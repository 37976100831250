import React, { useState, useEffect } from "react";
import * as S from "./style";
import api from "../../services/api";
import { Redirect } from "react-router-dom";
import logo from "../../assets/logo-futplace@2x.png";
import swal from "sweetalert";

function Login() {
  const [login, setLogin] = useState();
  const [password, setPassword] = useState();
  const [redirect, setRedirect] = useState(false);

  async function auth() {
    if (!login) {
      return swal({
        title: "Insira um usuário!",
        icon: "warning",
        button: "OK",
      });
    } else if (!password) {
      return swal({
        title: "Insira uma senha!",
        icon: "warning",
        button: "OK",
      });
    }

    await api
      .get(`/app/login/`, {
        auth: {
          username: login,
          password: password,
        },
      })
      .then((response) => {
        localStorage.setItem("@futplace", response.data.token);
        localStorage.setItem("@futplace/id", response.data.user.idempresa);
        setRedirect(true);
        window.location.reload();
      })
      .catch((response) => {
        return swal({
          title: "Usuário ou senha incorreto!",
          icon: "error",
          button: "OK",
        });
      });
  }

  return (
    <S.Container>
      {redirect && <Redirect to="/App" />}
      <S.Form>
        <S.Div>  
          <img width="85%" src={logo} alt="Futplace logo" />
          <hr />
          <input
            type="text"
            placeholder="Login"
            onChange={(e) => {
              setLogin(e.target.value);
            }}
          />
          <input
            type="password"
            placeholder="Senha"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <button type="button" onClick={auth}>
            Entrar
          </button>
        </S.Div>
      </S.Form>
    </S.Container>
  );
}

export default Login;
