import styled from 'styled-components';

export const Container = styled.div`
    
    button.fab-item.undefined.fab-rotate, button.fab-item.fab-item.btn.btn-link.btn-lg.text-white, button.fab-item.undefined   {
      background-color: #26c601 !important;
    }


    @media (max-width: 575.98px) {
      button.fab-item.undefined.fab-rotate{
      }

      button.fab-item.fab-item.btn.btn-link.btn-lg.text-white, button.fab-item.undefined   {

      }
    }

    @media (min-width: 576px) and (max-width: 724.98px) {}

    @media (min-width: 725px) and (max-width: 991.98px) {
      
    }

    @media (min-width: 992px) and (max-width: 1208.98px) {
     
    }

    @media (min-width: 1209px) {
      button.fab-item.undefined.fab-rotate{
        background-color: #26c601 !important;
      }

      button.fab-item.fab-item.btn.btn-link.btn-lg.text-white, button.fab-item.undefined   {
        
      }
    }
`

