import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    Height: 60px;
    background: #999;
    display: flex;
    *, *::before, *::after {
            box-sizing: content-box;
        }
`

export const LeftSide = styled.div`
    width: 50%;
    Height: 60px;
    background: white;
    border-bottom: 3px solid #26C601;
    padding-left: 100px;
    display: flex;
    align-items: center;

    img {
        width: 164px;
        Height: 50px;
    }

    span#icone {
      display: none;
      
    }

    span#icone img {
      padding-top: 2px;
      width: 29px;
      height: 40px;
    }

    @media (max-width: 400px) {
      padding-left: 0px;

      span#icone {
        display: block;
        padding-left: 5px;
      }

      img#logo {
        display: none;
      }
    }

    @media (min-width: 401px) and (max-width: 575.98px) {
      
      span#icone {
        display: block;
        padding-left: 15px;
      }

      img#logo {
        display: none;
      }
    }

    

    @media (min-width: 576px) and (max-width: 767.98px) {
      padding-right: 0px;


      a {
        font-size: 1.5em
      }
    }

    @media (max-width: 767.98px) {
      padding-left: 20px;

    }


`

export const RightSide = styled.div`
    width: 50%;
    Height: 60px;
    background: white;
    border-bottom: 3px solid #26C601;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 100px;
    font-size: 13px;

    a {
        color: #313030;
        text-decoration: none;
        font: Bold 1.8em Nunito;
        letter-spacing: 1px;
        color: #2D2D2D;
        margin: 0 10px;
        text-align: center;
        -webkit-transition: 0.15s ease-in;
        -moz-transition: 0.15s ease-in;
        -o-transition: 0.15s ease-in;
        transition: 0.15s ease-in;

        &:hover {
            color: #26C601;
        }
    }

    #notification {
        display: flex;
        align-items: center;
        border: 0;
        background: none;
        -webkit-transition: 0.15s ease-in;
        -moz-transition: 0.15s ease-in;
        -o-transition: 0.15s ease-in;
        transition: 0.15s ease-in;

        img {
            width: 27px;
            Height: 30px;
        }


        span#number {
            background: #26C601;
            color: white;
            font: Bold 0.5em Nunito;
            padding: 2px 6px;
            border-radius: 50%;
            position: relative;
            top: -9px;
            right: 11px;

        }

        &:hover {
            opacity: 0.5;
            
        }
    }

    .dividir {
        width: 1px;
        Height: 25px;

        margin: 0 10px;
        background: #2D2D2D;
    }

    @media (max-width: 400px) {
      padding-right: 0px;

      a {
        font-size: 1.6em;
      }

      span.dividir {
        display: none;
      }

    }

    @media (max-width: 575.98px) {
      font-size: 0.5em;
      padding-right: 0px;
    }

    @media (min-width: 576px) and (max-width: 767.98px) {
      padding-right: 20px;


      a {
        font-size: 1.5em
      }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
      a {
        font-size: 1.5em
      }
    }

    @media (min-width: 992px) and (max-width: 1199.98px) {
      
    }
   

`