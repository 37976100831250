

import React, {useState} from 'react';
import * as S from './style';

import { Container, Button} from 'react-floating-action-button';
import Modal from "react-bootstrap/Modal";
import { Button as Btn} from 'react-bootstrap';
import SettingsIcon from '@material-ui/icons/Settings';
import SvgIcon from '@material-ui/icons/Settings';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

function FloatButton() {
  const [modalCampShow, setModalCampShow] = useState(false);
  const handleCampClose = () => setModalCampShow(false);


  return (
    <S.Container>
      <Container>
        <Button href="/"
            tooltip="Sair"
            onClick={() => (localStorage.clear(), window.location.replace(`/login/`))}>
              <SvgIcon component={ExitToAppIcon}/>

        </Button>
        <Button
            tooltip="Suporte"
            className="fab-item btn btn-link btn-lg text-white"
            onClick={() => (setModalCampShow(true))}>
              <SvgIcon component={ContactSupportIcon}/>
        </Button>
        <Button
            id="config"
            tooltip="Ajuda/Sair"
            rotate={true}
            id="configButton">
              <SvgIcon component={SettingsIcon}/>

        </Button>
      </Container>
      <Modal
        size="xs"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalCampShow}
        onHide={handleCampClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Contato do suporte:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>&nbsp;&nbsp;&nbsp;Email: futplaceapp@gmail.com</p>
        </Modal.Body>
        <Modal.Footer>
          <Btn onClick={() => (setModalCampShow(false))}>Voltar</Btn>
        </Modal.Footer>
      </Modal>
    </S.Container>
);
}

export default FloatButton;
