import styled from 'styled-components';
export const Container = styled.div`
    width: 100%;

    nav.fab-container.undefined {
      position: fixed;
      right: 20px;
      bottom: 20px;
    }

    @media (max-width: 575.98px) {
      nav.fab-container.undefined {
        right: 5px;
        bottom: 5px;
      }
    }

    @media (min-width: 576px) and (max-width: 724.98px) {
      nav.fab-container.undefined {
        right: 10px;
        bottom: 10px;
      }
    }

    @media (min-width: 725px) and (max-width: 991.98px) {
      
    }

    @media (min-width: 992px) and (max-width: 1208.98px) {
    
    }

    @media (min-width: 1209px) {

    }

`

export const FilterArea = styled.div`
    width: 100%;
    display: flex;
    -webkit-display: flex;
    justify-content: space-around;
    -webkit-justify-content: space-around;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    margin-top: 30px;

    button.custom-button, a.custom-button {
        background: none;
        border: none;

        *, *::before, *::after {
            box-sizing: content-box;
        }
    }
    
`

export const Tittle = styled.div `
    width: 100%;
    text-align: center;
    
    div.left {
        width: 100%;
        background: white;
        height: 2px;
        top: 43px !important;
        position: relative;
    }

    h3.agendamento {
        color: #FFF;
        font: 23px Nunito;
        position: relative;
        display: inline-block;
        background: #2D2D2D;
        padding: 0px 10px;
        margin-top: 26px;
        margin-bottom: 26px;
        
    }

    @media (max-width: 575.98px) {
      div.left {
        top: 30px !important;
      }

      h3.agendamento {
        margin-top: 14px;
        font-size: 20px;
      }
    }

    @media (min-width: 576px) and (max-width: 724.98px) {
      div.left {
        top: 30px !important;
      }

      h3.agendamento {
        margin-top: 12px;
        font-size: 23px;
      }
    }

    @media (min-width: 725px) and (max-width: 991.98px) {
      
    }

    @media (min-width: 992px) and (max-width: 1208.98px) {
    
    }

    @media (min-width: 1209px) {

    }
`

export const InputRadio = styled.div`
    flex: 0 0 47%;
    max-width: 47%;
    font-family: Nunito;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    
`

export const InputRadioDuracao = styled.div`
    flex: 0 0 47%;
    max-width: 47%;
    font-family: Nunito;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative; 
    margin-bottom: 20px;

    
`
export const InputTwo = styled.div`
    font-family: Nunito;
    display: flex;
    justify-content: space-between;
    
`

export const InputRadioRadio = styled.div`
    font-family: Nunito;
    display: flex;
    flex-direction: row;
    position: relative;
    top: 3px;

    input {
        background: none;
        border: none;
        color: white;
        margin-left: 10px;
    }

    label {
        display: inline;
        margin-left: 5px;
    }

`

export const InputTwoRadio = styled.div`
    font-family: Nunito;
    display: flex;
    justify-content: space-between;
    
    @media (max-width: 575.98px) {
      span {
        font-size: 16px !important;
      }

      label {
        font-size: 14px !important;
        display: inline-block !important;
        margin-left: 0 !important;
        padding-right: 0 !important;
      }

      input[type=radio] {
        height: 8px;
        padding: 0px !important;
        margin-left: 5px !important;
    }
    }

`

export const InputTwoArea = styled.div`
    flex: 0 0 47%;
    max-width: 47%;
    font-family: Nunito;
    display: flex;
    flex-direction: column;
    position: relative;



    input {
        width: 98%;
        background: none;
        border: none;
        color: black;
        padding: 2px;
        border-bottom: 1px solid black;
        margin-bottom: 20px;
    }

    
`
