import React, {useState, useEffect} from 'react';
import * as S from './style';
import api from '../../services/api';
import {Redirect} from 'react-router-dom';


// Componentes
// ================================
import Header from '../../components/Header';
import Calendario from '../../components/Calendario';
import FilterCard from '../../components/FilterCard';

function AgendaV() {
    const [idempresa] = useState(localStorage.getItem('@futplace/id'));
    const [campActived, setCampActived] = useState(0);
    const [redirect, setRedirect] = useState(false); 
    const [campos, setCampos] = useState([]);

    async function getCamps () {
      await api.get(`/empresa/camps/${idempresa}`)
      .then(response => {
          response.data.forEach((item) => (setCampos(item.camps)))
          
      })
    }

    useEffect(() => {
      if(!localStorage.getItem("@futplace")) {
        setRedirect(true);
      }
      getCamps();
    }, []);
    
    
    return (
      <S.Container>
      
        {redirect && <Redirect to="/login"/>}

        <Header />

        <S.FilterArea>
          {campos.map((row, index) => (
              <button className="custom-button" type="button" onClick={() => setCampActived(row.camp)}>
                  <FilterCard tittle={`Campo ${row.camp}`} actived={campActived === row.camp} />
              </button>
            ))
          }
        </S.FilterArea>
        <hr/>
        <Calendario camp={campActived} />
        
        </S.Container>

    )
}

export default AgendaV;
