import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    


    @media (max-width: 767.98px) {

      .fa-lg {
        font-size: 1em !IMPORTANT;
        top: 25px;
        right: 3px;
      }
    }

`

export const Form = styled.div`
    margin-top: 50px;
    width: 50%;

    @media (max-width: 575.98px) {
      width: 90%;

      input{
        font-size: 12px !IMPORTANT;
      }
    }

    @media (min-width: 576px) and (max-width: 767.98px) {
      width: 90%;


      input {
        font-size: 5px;
      }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
      width: 80%;
    }

    @media (min-width: 992px) and (max-width: 1199.98px) {
      width: 60%;
    }
`

export const Input = styled.div`
    font-family: Nunito;
    display: flex;
    flex-direction: column;

    span {
        color: #26C601;
        font-size: 18px;
    }

    input {
        font-size: 22px;
        background: none;
        border: none;
        color: white;
        padding: 2px;
        border-bottom: 1px solid #26c601;
        margin-bottom: 20px;
    }

    
    
`

export const InputTwo = styled.div`
    font-family: Nunito;
    display: flex;
    justify-content: space-between;
    
`
export const InputTwoRadio = styled.div`
    font-family: Nunito;
    display: flex;
    justify-content: space-between;
    
    @media (max-width: 575.98px) {
      span {
        font-size: 16px !important;
      }

      label {
        font-size: 14px !important;
        display: inline-block !important;
        margin-left: 0 !important;
        padding-right: 0 !important;
      }

      input[type=radio] {
        height: 8px;
        padding: 0px !important;
        margin-left: 5px !important;
    }
    }

    @media (min-width: 576px) and (max-width: 767.98px) {
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
    }

    @media (min-width: 992px) and (max-width: 1199.98px) {
    }
`

export const InputTwoArea = styled.div`
    flex: 0 0 47%;
    max-width: 47%;
    font-family: Nunito;
    display: flex;
    flex-direction: column;
    position: relative;

    svg {
        color: #26C601;
        position: absolute;
        right: 10px;
        top: 28px;
    }

    span {
        color: #26C601;
        font-size: 18px;
    }

    input {
        width: 98%;
        font-size: 22px;
        background: none;
        border: none;
        color: white;
        padding: 2px;
        border-bottom: 1px solid #26c601;
        margin-bottom: 20px;
    }

    
`

export const InputTreeArea = styled.div`
    flex: 0 0 31%;
    max-width: 31%;
    font-family: Nunito;
    display: flex;
    flex-direction: column;
    position: relative;

    svg {
        color: #26C601;
        position: absolute;
        right: 10px;
        top: 28px;
    }

    span {
        color: #26C601;
        font-size: 18px;
    }

    input {
        width: 98%;
        font-size: 22px;
        background: none;
        border: none;
        color: white;
        padding: 2px;
        border-bottom: 1px solid #26c601;
        margin-bottom: 20px;

    }
`

export const InputRadio = styled.div`
    flex: 0 0 47%;
    -webkit-flex: 0 0 47%;
    max-width: 47%;
    -webkit-max-width: 47%;
    font-family: Nunito;
    -webkit-font-family: Nunito;
    display: flex;
    -webkit-display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    align-items: center;
    -webkit-align-items: center;
    margin-bottom: 20px;

    span {
        color: #26C601;
        font-size: 18px;
        display: inline;
    }
    
`

export const InputRadioDuracao = styled.div`
    flex: 0 0 47%;
    max-width: 47%;
    font-family: Nunito;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative; 
    margin-bottom: 20px;

    span {
        color: #26C601;
        font-size: 18px;
        display: inline;
    }

    
    
`

export const InputRadioRadio = styled.div`
    font-family: Nunito;
    display: flex;
    flex-direction: row;
    position: relative;
    top: 3px;

    input {
        font-size: 22px;
        background: none;
        border: none;
        color: white;
        padding: 10px;
        margin-left: 20px;
    }

    label {
        color: #26C601;
        font-size: 18px;
        display: inline;
        margin-left: 5px;
        padding-right: 20px;
    }

    @media (max-width: 575.98px) {

      ${'' /* input {
        padding: 0;
      }

      label{
        font-size: 15px !IMPORTANT;
        margin-left: 0;
        padding: 5px;
        text-align: center;
      } */}
    }
    
`

export const InputTipoAgendamento = styled.div `
    flex: 0 0 99%;
    max-width: 99%;
    font-family: Nunito;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    span {
        color: #26C601;
        font-size: 18px;
        display: inline;
    }
`


export const Options = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto; 
    margin-top: 30px;
    width: 98%;

    .btn-group {
      border: 1px solid red;
      border-radius: 5px;
    }
    
    

    input {
      margin-bottom: 2px;
    }

    button {
        font-family: Nunito;
        color: red;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1px;
        transition: 0.4s;

        &:hover {
            opacity: 0.7;
        }
    }

    label {
      margin-left: 7px;
      margin-bottom: 0;
    }

    div {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 1px;
        font-family: Nunito;
        display: flex;
        align-items: center;
        color: #26C601;
    }
    
    
    @media (max-width: 575.98px) {
      a.dropdown-item {
        font-size: 13px;
      }
    }

    @media (min-width: 576px) and (max-width: 767.98px) {
      a.dropdown-item {
        font-size: 15px;
      }
    }
`

export const Save = styled.div`
    width: 100%;
    margin-top: 20px;
    
    button {
        width: 100%;
        height: 45px;
        font-family: Nunito;
        color: white;
        background: #26C601;
        border-radius: 25px;
        border: none;
        cursor: pointer;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 1px;
        transition: 0.4s;

        &:hover {
            opacity: 0.7;
        }

    }

    
`
