import React, { useState, useEffect } from "react";
import * as S from "./style";

// Componentes
// ================================
import logo from "../../assets/logo-futplace@2x.png";
import logoicone from "../../assets/logo-futplace-refeito.png";

function Termos() {
  useEffect(() => {}, []);

  return (
    <S.Container>
      <S.Header>
        <div>
          <img id="logo" src={logo} alt="Logo Futplace" />
          <span id="icone">
            <img src={logoicone}></img>
          </span>
        </div>
      </S.Header>
      <S.Termos>
        <h4 align="center">TERMOS DE USO</h4>
        <br/>
        <strong>1. Termos & Condições Gerais</strong>
        <br/>
        <p>Este é um contrato firmado entre você, de agora em diante 
        denominado como “usuário”, e com o FUTPLACE INTERMEDIAÇÃO DE 
        NEGÓCIOS E MARKETING LTDA, com personalidade jurídica devidamente 
        inscrita no CNPJ 38.078.170/0001-61, situada em Recife - PE, na 
        Rua Eugênio de Lima, 143 - Sala 01. Este termo “Termos de uso de 
        aplicativo” rege o uso do Aplicativo FutPlace, seja para dispositivos
         móveis (Android e IOS), computadores ou serviços web. Se discorda destes 
         termos e condições ou alguma parte dos mesmos, não deve usar o nosso aplicativo. 
         Leia atentamente, pois o uso deste aplicativo significa que você aceitou todos os 
         termos e concorda em cumpri-los.
        </p>
        <strong>2. Conta do Usuário, Registro de Conta e Eliminação de Conta.</strong>
        <br/>
        <br/>
        <strong>Conta do Usuário</strong>
        <br/>
        <p>Para ser um usuário, e ter acesso a alguns recursos de nosso 
        aplicativo que o permitem reservar campos, favoritar campos, 
        gerir suas reservas e editar seu perfil é necessário criar uma 
        conta no aplicativo FutPlace. Um usuário apenas pode se registrar 
        no Aplicativo FutPlace em seu próprio nome e utilizando suas 
        próprias informações. O FutPlace se reserva o direito de rejeitar 
        um registro de conta sem a necessidade de declarar os motivos de rejeição.
        </p>
        <br/>
        <strong>Registro de Conta</strong>
        <br/>
        <p>O registro é realizado através de um pequeno formulário online, 
        presente no aplicativo FutPlace, no qual o usuário deve preencher 
        todos os dados solicitados (Especificamente: Nome e sobrenome, 
        endereço de e-mail, número de telefone, CPF e a senha). A senha 
        é armazenada após ser criptografada, para garantir a segurança 
        da conta do usuário. Na ausência de um registro de conta, o aplicativo 
        FutPlace poderá ser usado apenas numa extensão muito limitada. 
        Enfatizamos que a equipe FutPlace possui acesso às contas dos 
        usuários, podendo assim criar novas contas, editar contas (quando necessário) e excluir contas.
        </p>
        <br/>
        <strong>Eliminação de Conta</strong>
        <br/>
        <p>As contas dos usuários, ao serem criadas, não possuem prazo 
        para serem excluídas. Ambas as partes têm o direito de, a qualquer 
        momento e sem a necessidade de declarar qualquer motivação, de 
        excluir sua conta, bem como os dados vinculados a ela. A eliminação 
        de uma conta do usuário é coberta pela nossa “Política de Privacidade”.
        </p>
        <strong>3. Reservas</strong>
        <br/>
        <br/>
        <strong>Confirmação de Reserva</strong>
        <br/>
        <p>Ao reservar um campo, o usuário aceita os respectivos preços 
        anunciados ou registrados no sistema, bem como quaisquer possíveis 
        regras estabelecidas pelo clube/parceiro em suas instalações. Após 
        efetuar a solicitação de reserva, o usuário receberá uma confirmação 
        ou rejeição da mesma através de uma notificação em seu dispositivo móvel 
        (Android ou IOS). O FutPlace não se responsabiliza por conflitos de 
        horários entre usuários do aplicativo ou clientes que reservaram seus 
        horários sem o uso do aplicativo, sendo de responsabilidade do, denominado, 
        parceiro estar atualizando seus horários disponíveis em nossa plataforma web.
        </p>
        <br/>
        <strong>Cancelamento de Reserva</strong>
        <br/>
        <p>Enquanto usuário, você pode cancelar a sua reserva, através de contato 
        direto com o parceiro em questão ou, em último caso, entrando em contato 
        com a equipe de suporte do FutPlace. O FutPlace reserva-se o direito de 
        desativar, por tempo indeterminado, ou excluir a sua conta em caso de 
        cancelamentos repetitivos ou muito próximos ao horário reservado.
        </p>
        <br/>
        <strong>Alteração de Reserva</strong>
        <br/>
        <p>Enquanto usuário, você só poderá alterar sua reserva, através de 
        contato direto com o parceiro em questão ou, em último caso, entrando 
        em contato com a equipe de suporte do FutPlace. O usuário deverá 
        cancelar sua reserva feita previamente e, caso disponível, reagendar 
        com as alterações que desejar. O parceiro tem permissão para alterar 
        sua reserva através de nossa plataforma Web.
        </p>
        <br/>
        <strong>Falta de comparecimento</strong>
        <br/>
        <p>Enquanto usuário, se fizer reservas através do aplicativo FutPlace 
        e não comparecer no campo repetidamente, sem cancelar sua reserva, o 
        FutPlace se reserva o direito de desativar, por tempo indeterminado, 
        ou excluir sua conta.
        </p>
        <strong>4. Preços e Pagamentos</strong>
        <br/>
        <p>A utilização desse aplicativo, por parte do usuário, é oferecida 
        de forma gratuita. Você não precisará efetuar nenhum pagamento para 
        baixar, acessar e se cadastrar no aplicativo FutPlace. Todo e qualquer 
        pagamento antes ou após usufruir de algumas das instalações dos parceiros 
        (campos registrados) devem ser realizados no local em questão. O FutPlace 
        não se beneficiará recebendo pagamentos dos usuários do aplicativo, sendo 
        assim, nenhuma pessoa vinculada a FutPlace irá receber pagamentos pelos 
        serviços oferecidos pelos nossos parceiros.
        </p>
        <strong>5. Alterações, Modificações e Rescisão</strong>
        <br/>
        <p>O FutPlace reserva-se o direito de, a qualquer momento, modificar 
        estes termos seja removendo, incluindo ou alterando quaisquer de suas 
        cláusulas. Tais modificações terão efeito imediato. Após publicadas as 
        alterações, ao continuar com o uso do aplicativo, você terá aceitado e 
        concordado em cumprir os termos modificados. O usuário não poderá 
        responsabilizar o FutPlace, nem seus diretores, executivos, funcionários, 
        filiados, agentes contratos ou licenciadores por quaisquer modificações, 
        suspensões ou descontinuidades do aplicativo.
        </p>
        <strong>6. Isenção de Garantias e limitações de responsabilidade</strong>
        <br/>
        <p>Este aplicativo está em constante desenvolvimento e, por isso, pode 
        apresentar erros, dessa forma o uso é fornecido “no estado em que se encontra” 
        e sob risco final do usuário. O FutPlace não garante que a operação deste 
        aplicativo seja contínua e sem erros. Exceto pelo estabelecido neste documento 
        e em nossa “Política de privacidade” não há outras garantias, condições ou promessas, 
        expressas ou implícitas, e todas essas garantias, condições e promessas podem ser 
        excluídas sem prejuízo ao FutPlace e seus colaboradores.
        </p>
        <strong>7. Definição de Termos e Palavras</strong>
        <br/>
        <p>O Aplicativo apresenta o uso de alguns termos e palavras que podem vir 
        a ser desconhecidos pelo usuário, portanto, serão apresentadas algumas 
        definições a fim de evitar confusões, ambiguidades e entendimentos errôneos. 
        Usuário(a), cliente, jogador representa qualquer um que venha a se registrar 
        em nosso aplicativo; Reserva representa o ato de solicitar um ou mais horários 
        em um dos estabelecimentos cadastrados; Parceiros: estabelecimentos que alugam 
        suas quadras esportivas através do nosso aplicativo.
        </p>
        <br/>
        <br/>
        <p>
        FutPlace
        </p>
        <p>
        Recife, 26 de Abril de 2021
        </p>
      </S.Termos>
    </S.Container>
  );
}

export default Termos;
