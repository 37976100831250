import React, { useState, useEffect } from "react";
// import * as S from './style';
import api from "../../services/api";

// Componentes
// ================================
import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";

import {
  ScheduleComponent,
  Day,
  Week,
  Month,
  Inject,
  ViewsDirective,
  ViewDirective,
} from "@syncfusion/ej2-react-schedule";
import { loadCldr } from "@syncfusion/ej2-base";

loadCldr(
  require("cldr-data/supplemental/numberingSystems.json"),
  require("cldr-data/main/pt-PT/ca-gregorian.json"),
  require("cldr-data/main/pt-PT/numbers.json"),
  require("cldr-data/main/pt-PT/timeZoneNames.json"),
  require("cldr-data/main/pt-PT/dateFields.json")
);

function Calendario({ camp }) {
  const [idempresa] = useState(localStorage.getItem("@futplace/id"));
  const [horariosOcupados, setHorariosOcupados] = useState([]);
  const [excludeTimes, setExcludeTimes] = useState();

  async function getExcludeTimes() {
    await api.get(`/task/time/${idempresa}`).then((response) => {
      setExcludeTimes(response.data);
      insertExcludesTimes(response.data);
    });
  }

  function insertExcludesTimes(excludeTimes) {
    var i = 0;
    var arrayHorarios = [];
    if (excludeTimes || arrayHorarios) {
      for (i = 0; i < excludeTimes.length; i++) {
        if (excludeTimes[i].nomecampo == camp) {
          arrayHorarios.push({
            Id: i,
            Subject: `Nome: ${excludeTimes[i].nome}`,
            StartTime: new Date(
              new Date(excludeTimes[i].when).setHours(
                new Date(excludeTimes[i].when).getHours() + 3
              )
            ),
            EndTime:
              excludeTimes[i].tipoHora == 1
                ? new Date(
                    new Date(excludeTimes[i].when).setHours(
                      new Date(excludeTimes[i].when).getHours() + 5
                    )
                  )
                : new Date(
                    new Date(excludeTimes[i].when).setHours(
                      new Date(excludeTimes[i].when).getHours() + 4,
                      new Date(excludeTimes[i].when).getMinutes() + 30
                    )
                  ),
          });
        }

        if (excludeTimes.length === arrayHorarios.length) {
          break;
        }
      }
    }

    setHorariosOcupados(arrayHorarios);
  }

  useEffect(() => {
    getExcludeTimes();
  }, [camp]);

  return (
    <ScheduleComponent
      height="550px"
      width="80%"
      locale="pt-PT"
      selectedDate={new Date()}
      eventSettings={{ dataSource: horariosOcupados }}
      readonly
    >
      <ViewsDirective>
        <ViewDirective option="Day" />
        <ViewDirective option="Week" />
        <ViewDirective option="Month" />
      </ViewsDirective>
      <Inject services={[Day, Week, Month]} />
    </ScheduleComponent>
  );
}

export default Calendario;
