import React, { useState, useEffect } from "react";
import * as S from "./style";
import api from "../../services/api";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/pt-br";

import logo from "../../assets/logo-futplace@2x.png";
import bell from "../../assets/bell.png";
import logoicone from "../../assets/logo-futplace-refeito.png";

function Header() {
  const idempresa = localStorage.getItem("@futplace/id");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [pendenceCount, setPendenceCount] = useState();
  const [pendences, setPendences] = useState([]);
  const [alreadyLoad, setAlreadyLoad] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function pendenceVerify() {
    await api.get(`/task/filter/pendences/${idempresa}`).then((response) => {
      setPendenceCount(response.data.length);
      setPendences(response.data);
      setAlreadyLoad(true);
    });
  }

  function tempoPassado(date) {
    var tempo;

    // Mostrando:
    tempo = moment(new Date(date).setHours(new Date(date).getHours() + 3))
      .locale("pt-BR")
      .fromNow(true);

    return tempo;
  }

  function getName(nome) {
    var firstname = "";

    for (var i = 0; i < nome.length; i++) {
      if (nome.charAt(i) == " ") {
        break;
      }

      firstname += nome.charAt(i);
    }

    return firstname;
  }

  useEffect(() => {
    if(alreadyLoad == false) {
      pendenceVerify();
    } 

    setInterval(() => {
      pendenceVerify();
    }, 60000)
  }, []);

  return (
    <S.Container>
      <S.LeftSide>
        <Link to="/App">
          <img id="logo" src={logo} alt="Logo Futplace" />
          <span id="icone">
            <img src={logoicone}></img>
          </span>
        </Link>
      </S.LeftSide>

      <S.RightSide>
        <Link to="/App">Início</Link>
        <span className="dividir" />
        <Link to="/task" onClick={() => window.location.replace(`/task/`)}>
          Novo agendamento
        </Link>
        <span className="dividir" />

        <div>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            id="notification"
          >
            <img src={bell} alt="notification"></img>
            <span id="number">
              {(pendenceCount == [] && "0") ||
                (pendenceCount !== 0 && pendenceCount)}
            </span>
          </IconButton>
          <Menu
            disableripple="true"
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                height: "400px",
                width: "280px",
              },
            }}
          >
            {pendences.map((pendences) => (
              <Link
                key={pendences._id}
                className="link-ref"
                to={`/task/${pendences._id}`}
                onClick={() =>
                  window.location.replace(`/task/${pendences._id}`)
                }
              >
                <MenuItem key={pendences._id} onClick={handleClose}>
                  <span id="telefone">Nome: {getName(pendences.nome)}</span>
                  <span align="right" id="tempo">
                    {tempoPassado(pendences.created)}
                  </span>
                </MenuItem>
              </Link>
            ))}
          </Menu>
        </div>
      </S.RightSide>
    </S.Container>
  );
}

export default Header;
