import React, { useState, useEffect } from "react";
import * as S from "./style";
import api from "../../services/api";
import InputMask from "react-input-mask";
import DatePicker, { registerLocale } from "react-datepicker";
import { SplitButton, Dropdown } from "react-bootstrap";
import moment from "moment";
import { Redirect } from "react-router-dom";
import ptBR from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import swal from "sweetalert";

// Componentes
// ================================
import Header from "../../components/Header";
import { faCalendar, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

registerLocale("pt-BR", ptBR);

function Task({ match }) {
  const [idagendamento, setIdAgendamento] = useState();
  const [idempresa] = useState(localStorage.getItem("@futplace/id"));
  const [nomeempresa, setNomeEmpresa] = useState();
  const [idcampo, setIdCampo] = useState();
  const [nomecampo, setNomeCampo] = useState();
  const [tipo] = useState(0);
  const [status, setStatus] = useState(2);
  const [date, setDate] = useState(new Date());
  const [hour, setHour] = useState();
  const [nome, setNome] = useState();
  const [cel, setCel] = useState();
  const [email, setEmail] = useState();
  const [cpf, setCpf] = useState();
  const [tipoHora, setTipoHora] = useState(0);
  const [tipoAgendamento, setTipoAgendamento] = useState(0);
  const [excludeTimes, setExcludeTimes] = useState();
  const [minhour, setMinHour] = useState(0);
  const [maxhour, setMaxHour] = useState(0);
  const [campos, setCampos] = useState([]);
  const [horariosOcupados, setHorariosOcupados] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [redirectLogin, setRedirectLogin] = useState(false);
  const [checkMensal, setCheckMensal] = useState(true);
  const [diaSemana, setDiaSemana] = useState();
  const [modalQuestionShow, setModalQuestionShow] = useState(false);
  const handleQuestionClose = () => setModalQuestionShow(false);

  // Recupera nome da empresa
  async function getName() {
    let name = localStorage.getItem("@futplace/name");

    if (!name) {
      await api.get(`/empresa/getName/${idempresa}`).then((response) => {
        localStorage.setItem("@futplace/name", response.data.nome);
        setNomeEmpresa(response.data.nome);
      });
    } else {
      setNomeEmpresa(name);
    }
  }

  // Recupera campos da empresa
  async function getCamps() {
    let json_campos = localStorage.getItem("@futplace/campos");

    if (!json_campos || json_campos == []) {
      await api.get(`/empresa/camps/${idempresa}`).then((response) => {
        localStorage.setItem(
          "@futplace/campos",
          JSON.stringify(response.data[0].camps)
        );
        response.data.forEach((item) => setCampos(item.camps));
      });
    } else {
      setCampos(JSON.parse(json_campos));
    }
  }

  // Recupera o horário de trabalho da empresa de acordo com o dia
  async function getTime(date) {
    let json_time = JSON.parse(localStorage.getItem("@futplace/time"));

    if (!json_time || json_time == []) {
      await api.get(`/empresa/time/${idempresa}`).then((response) => {
        localStorage.setItem("@futplace/time", JSON.stringify(response.data));
        response.data.forEach((item) => {
          if (moment(date).format("dddd") === "domingo") {
            setMaxHour(item.maxhour[0]);
            setMinHour(item.minhour[0]);
            setDiaSemana(0);
          } else if (moment(date).format("dddd") === "segunda-feira") {
            setMaxHour(item.maxhour[1]);
            setMinHour(item.minhour[1]);
            setDiaSemana(1);
          } else if (moment(date).format("dddd") === "terça-feira") {
            setMaxHour(item.maxhour[2]);
            setMinHour(item.minhour[2]);
            setDiaSemana(2);
          } else if (moment(date).format("dddd") === "quarta-feira") {
            setMaxHour(item.maxhour[3]);
            setMinHour(item.minhour[3]);
            setDiaSemana(3);
          } else if (moment(date).format("dddd") === "quinta-feira") {
            setMaxHour(item.maxhour[4]);
            setMinHour(item.minhour[4]);
            setDiaSemana(4);
          } else if (moment(date).format("dddd") === "sexta-feira") {
            setMaxHour(item.maxhour[5]);
            setMinHour(item.minhour[5]);
            setDiaSemana(5);
          } else if (moment(date).format("dddd") === "sábado") {
            setMaxHour(item.maxhour[6]);
            setMinHour(item.minhour[6]);
            setDiaSemana(6);
          }
        });
      });
    } else {
      json_time.forEach((item) => {
        if (moment(date).format("dddd") === "domingo") {
          setMaxHour(item.maxhour[0]);
          setMinHour(item.minhour[0]);
          setDiaSemana(0);
        } else if (moment(date).format("dddd") === "segunda-feira") {
          setMaxHour(item.maxhour[1]);
          setMinHour(item.minhour[1]);
          setDiaSemana(1);
        } else if (moment(date).format("dddd") === "terça-feira") {
          setMaxHour(item.maxhour[2]);
          setMinHour(item.minhour[2]);
          setDiaSemana(2);
        } else if (moment(date).format("dddd") === "quarta-feira") {
          setMaxHour(item.maxhour[3]);
          setMinHour(item.minhour[3]);
          setDiaSemana(3);
        } else if (moment(date).format("dddd") === "quinta-feira") {
          setMaxHour(item.maxhour[4]);
          setMinHour(item.minhour[4]);
          setDiaSemana(4);
        } else if (moment(date).format("dddd") === "sexta-feira") {
          setMaxHour(item.maxhour[5]);
          setMinHour(item.minhour[5]);
          setDiaSemana(5);
        } else if (moment(date).format("dddd") === "sábado") {
          setMaxHour(item.maxhour[6]);
          setMinHour(item.minhour[6]);
          setDiaSemana(6);
        }
      });
    }

    return;
  }

  // Carrega um agendamento
  async function loadAgendamento() {
    await api.get(`/task/${match.params.id}`).then((response) => {
      setIdAgendamento(response.data.idagendamento);
      setNome(response.data.nome);
      setEmail(response.data.email);
      setCel(response.data.cellphone);
      setDate(new Date(response.data.when));
      setHour(
        new Date(response.data.when).setHours(
          new Date(response.data.when).getHours() + 3
        )
      );
      setNomeCampo(response.data.nomecampo);
      setIdCampo(response.data.idcampo);
      setStatus(response.data.status);
      setTipoHora(response.data.tipoHora);
      setTipoAgendamento(response.data.tipoAgendamento);
    });
  }

  // Recupera os agendamentos de uma empresa
  async function getExcludeTimes() {
    await api.get(`/task/time/${idempresa}`).then((response) => {
      setExcludeTimes(response.data);
    });
  }

  function generateId(len) {
    var charSet =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var randomString = "";
    for (var i = 0; i < len; i++) {
      var randomPoz = Math.floor(Math.random() * charSet.length);
      randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
  }

  async function checkMensalDate() {
    if (hour != undefined) {
      await api
        .get(`/task/filter/checkdate/${idempresa}/`, {
          headers: {
            data: `${moment
              .utc(
                moment(date)
                  .hours(
                    moment(
                      new Date(hour).setHours(new Date(hour).getHours() - 3)
                    ).hours()
                  )
                  .minutes(moment(hour).minutes())
                  .seconds(0)
              )
              .format()}`,
            tipoHora: tipoHora,
            nomecampo: nomecampo,
          },
        })
        .then((response) => {
          setCheckMensal(response.data[0].disponibilidade);
        });
    } else {
      return swal({
        title: "Informe uma data e hora, por favor!",
        icon: "warning",
        button: "OK",
      });
    }
  }

  // Processo para cancelar as mensais de um cliente
  async function cancelAllMensalProcess() {
    await api
      .get(`/task/findmensal/${idagendamento}`)
      .then((response) => {
        response.data.forEach((item) => {
          if (new Date(item.when).getTime() >= date) {
            api.put(`/task/updatestatus/${item._id}/3`);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Exibe o modal para cancelamento de mensal
  async function cancelAllMensal() {
    swal({
      title: "Tem certeza?",
      text: "Você irá cancelar o agendamento atual e todos os seguintes!",
      icon: "warning",
      buttons: ["Cancelar", "OK"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        cancelAllMensalProcess();

        swal("Agendamento cancelado!", {
          icon: "success",
        });
        setRedirect(true);
      }
    });
  }

  // Cria agendimento
  async function Save() {
    if (!nome) {
      return swal({
        title: "Informe um nome, por favor!",
        icon: "warning",
        button: "OK",
      });
    }

    if (!email) {
      setEmail("");
    }

    if (!cel) {
      return swal({
        title: "Informe um telefone!",
        icon: "warning",
        button: "OK",
      });
    } else if (!validaNumero(cel)) {
      return swal({
        title: "Informe um telefone válido!",
        icon: "warning",
        button: "OK",
      });
    }

    if (tipoHora != 0 && tipoHora != 1) {
      setHour();
      return swal({
        title: "Informe uma duração!",
        icon: "warning",
        button: "OK",
      });
    }

    if (!hour || (isNaN(Date.parse(hour)) && !Number.isInteger(hour))) {
      return swal({
        title: "Informe um horário!",
        icon: "warning",
        button: "OK",
      });
    }

    if (tipoAgendamento != 0 && tipoAgendamento != 1) {
      return swal({
        title: "Informe um tipo de agendamento!",
        icon: "warning",
        button: "OK",
      });
    }

    if (new Date().getTime() > new Date(hour).getTime()) {
      return swal({
        title: "informe uma data futura, por favor!",
        icon: "warning",
        button: "OK",
      });
    }

    var idagendamento = generateId(20);
    var price = await setPrice();

    if (match.params.id) {
      await api
        .put(
          `/task/${match.params.id}`,
          {
            idagendamento: idagendamento,
            idempresa,
            nomeempresa: nomeempresa,
            nomecampo: nomecampo,
            tipo,
            status,
            when: moment(date)
              .hours(
                moment(
                  new Date(hour).setHours(new Date(hour).getHours() - 3)
                ).hours()
              )
              .minutes(moment(hour).minutes())
              .seconds(0)
              .format(),
            nome,
            cellphone: cel,
            email,
            price: price,
            tipoHora: tipoHora,
            tipoAgendamento: tipoAgendamento,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("@futplace")}`,
              type: tipo,
            },
          }
        )
        .then(() => {
          setRedirect(true);
        });
    } else {
      if (tipoAgendamento == 1) {
        var date2 = date;
        var dataSelec = moment(date)
          .hours(
            moment(
              new Date(hour).setHours(new Date(hour).getHours() - 3)
            ).hours()
          )
          .minutes(moment(hour).minutes())
          .seconds(0)
          .format();

        var dataFinal = moment(date2.setMonth(date2.getMonth() + 2))
          .hours(
            moment(
              new Date(hour).setHours(new Date(hour).getHours() - 3)
            ).hours()
          )
          .minutes(moment(hour).minutes())
          .seconds(0)
          .format();

        if (checkMensal == false) {
          while (
            new Date(dataSelec).getTime() < new Date(dataFinal).getTime()
          ) {
            await api
              .post(
                "/task",
                {
                  idagendamento: idagendamento,
                  idempresa,
                  nomeempresa: nomeempresa,
                  nomecampo,
                  tipo,
                  status: status,
                  when: dataSelec,
                  created: moment.utc(new Date()).format(),
                  nome,
                  cellphone: cel,
                  email,
                  price: price,
                  tipoHora: tipoHora,
                  tipoAgendamento: tipoAgendamento,
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "@futplace"
                    )}`,
                    type: tipo,
                  },
                }
              )
              .catch((error) => {
                return swal({
                  title: "Ocorreu um erro ao tentar realizar o agendamento!",
                  icon: "warning",
                  button: "OK",
                });
              });

            dataSelec = moment(
              new Date(dataSelec).setDate(new Date(dataSelec).getDate() + 7)
            )
              .hours(
                moment(
                  new Date(hour).setHours(new Date(hour).getHours() - 3)
                ).hours()
              )
              .minutes(moment(hour).minutes())
              .seconds(0)
              .format();
          }

          setRedirect(true);
        } else {
          return swal({
            title: "Já existe um agendamento marcado para uma das datas!",
            icon: "warning",
            button: "OK",
          });
        }
      } else {
        await api
          .post(
            "/task",
            {
              idagendamento: idagendamento,
              idempresa,
              nomeempresa: nomeempresa,
              nomecampo,
              tipo,
              status: status,
              when: moment(date)
                .hours(
                  moment(
                    new Date(hour).setHours(new Date(hour).getHours() - 3)
                  ).hours()
                )
                .minutes(moment(hour).minutes())
                .seconds(0)
                .format(),
              created: moment
                .utc(new Date().setHours(new Date().getHours() - 3))
                .format(),
              nome,
              cellphone: cel,
              email,
              price: price,
              tipoHora: tipoHora,
              tipoAgendamento: tipoAgendamento,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("@futplace")}`,
                type: tipo,
              },
            }
          )
          .then(() => {
            setRedirect(true);
          })
          .catch((error) => {
            return swal({
              title: "Ocorreu um erro ao tentar realizar o agendamento!",
              icon: "warning",
              button: "OK",
            });
          });
      }
    }
  }

  // Atualiza o status de um agendamento
  async function updateStatus(id) {
    swal({
      title: "Tem certeza?",
      text: "Você irá cancelar o agendamento!",
      icon: "warning",
      buttons: ["Cancelar", "OK"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        api.put(`/task/updatestatus/${id}/3`).then(() => setRedirect(true));

        swal("Agendamento cancelado!", {
          icon: "success",
        });
      }
    });
  }

  function validaNumero(strCel) {
    let cel = "";
    for (let i = 0; i < strCel.length; i++) {
      if (Number.isInteger(parseInt(strCel.charAt(i)))) {
        cel += strCel.charAt(i);
      }
    }

    if (cel.length !== 11) {
      return false;
    }
    return true;
  }

  // insere os horários ocupados no campo select
  async function insertExcludesTimes() {
    excludeTimes &&
      excludeTimes.map((row) =>
        row.nomecampo == nomecampo
          ? moment(moment(date).format("YYYY-MM-DD")).isSame(
              moment(row.when).format("YYYY-MM-DD")
            )
            ? horariosOcupados.push(
                tipoHora == 1
                  ? new Date(row.when).setHours(
                      new Date(row.when).getHours() + 2,
                      new Date(row.when).getMinutes() - 30
                    )
                  : [],
                new Date(row.when).setHours(new Date(row.when).getHours() + 2),
                new Date(row.when).setHours(
                  new Date(row.when).getHours() + 3,
                  new Date(row.when).getMinutes() - 30
                ),
                new Date(row.when).setHours(new Date(row.when).getHours() + 3),
                new Date(row.when).setHours(
                  new Date(row.when).getHours() + 3,
                  new Date(row.when).getMinutes() + 30
                ),
                new Date(row.when).setHours(new Date(row.when).getHours() + 4),
                row.tipoHora == 1
                  ? new Date(row.when).setHours(
                      new Date(row.when).getHours() + 4,
                      new Date(row.when).getMinutes() + 30
                    )
                  : []
              )
            : ""
          : ""
      );
  }

  function getTurno() {
    if (new Date(hour).getHours() < 12) {
      return 0;
    } else if (new Date(hour).getHours() < 18) {
      return 1;
    } else {
      return 2;
    }
  }

  // seta o preço em agedamento de acordo com o horário informado
  function setPrice() {
    if (nomecampo != undefined && hour != undefined) {
      if (tipoAgendamento == 0) {
        return campos.find((element) => element.camp == nomecampo).price[
          diaSemana
        ][getTurno()][tipoHora];
      } else {
        return campos.find((element) => element.camp == nomecampo).mensalprice[
          diaSemana
        ][getTurno()][tipoHora];
      }
    } else {
      return swal({
        title: "Informe o campo e horário, por favor!",
        icon: "warning",
        button: "OK",
      });
    }
  }

  // permitido: 123.456.789-09
  function validaCPF(strCPF) {
    let add;
    let rev;
    let cpf = "";

    for (let i = 0; i < strCPF.length; i++) {
      if (Number.isInteger(parseInt(strCPF.charAt(i)))) {
        cpf += strCPF.charAt(i);
      }
    }

    if (
      cpf.length !== 11 ||
      cpf === "00000000000" ||
      cpf === "11111111111" ||
      cpf === "22222222222" ||
      cpf === "33333333333" ||
      cpf === "44444444444" ||
      cpf === "55555555555" ||
      cpf === "66666666666" ||
      cpf === "77777777777" ||
      cpf === "88888888888" ||
      cpf === "99999999999"
    )
      return false;
    // Valida 1o digito
    add = 0;
    for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(9))) return false;
    // Valida 2o digito
    add = 0;
    for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(10))) return false;
    return true;
  }

  // Recupera a hora, por exemplo 18:30, retorna 18
  function getHourWithHalf(hour) {
    if (typeof hour == "string") {
      let hour_splitted = hour.split(":");
      return hour_splitted[0];
    }
  }

  // seta horário mínimo de acordo com o selecionado
  function setMintime() {
    if (minhour.length > 2) {
      return new Date().setHours(getHourWithHalf(minhour), 30);
    } else {
      return new Date().setHours(minhour, 0);
    }
  }

  // seta horário máximo de acordo com o selecionado
  function setMaxtime() {
    if (tipoHora == 1) {
      if (maxhour.length > 2) {
        return new Date().setHours(getHourWithHalf(maxhour) - 1, -30);
      } else {
        return new Date().setHours(maxhour - 2, 0);
      }
    } else {
      if (maxhour.length > 2) {
        return new Date().setHours(getHourWithHalf(maxhour) - 1, 0);
      } else {
        return new Date().setHours(maxhour - 1, -30);
      }
    }
  }

  useEffect(() => {
    if (!localStorage.getItem("@futplace")) {
      setRedirectLogin(true);
    }
    if (match.params.id) {
      loadAgendamento();
    }
    getTime();
    getCamps();
    getExcludeTimes();
    getName();
  }, []);

  return (
    <S.Container>
      {redirectLogin && <Redirect to="/login" />}
      {redirect && <Redirect to="/App" />}

      <Modal
        size="xs"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalQuestionShow}
        onHide={handleQuestionClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Tem certeza?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleQuestionClose}>
            Close
          </Button>
          <Button onClick={() => (setModalQuestionShow(false), updateStatus())}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

      <Header teste={loadAgendamento} />
      <S.Form>
        <S.Input>
          <span>Nome</span>
          <input
            type="text"
            placeholder="Nome Completo"
            onChange={(e) => setNome(e.target.value)}
            value={nome}
          />
        </S.Input>

        <S.Input>
          <span>Email</span>
          <input
            type="text"
            placeholder="Email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
          />
        </S.Input>

        <S.Input>
          <span>Telefone</span>
          <InputMask
            type="text"
            placeholder="telefone"
            mask="(99) 99999-9999"
            onChange={(e) => {
              setCel(e.target.value);
            }}
            value={cel}
          ></InputMask>
        </S.Input>

        <S.InputTwoRadio>
          <S.InputRadio>
            <span>Campo:</span>
            <S.InputRadioRadio>
              {campos.map((row, index) => (
                <div key={index}>
                  <input
                    id={row.camp}
                    type="radio"
                    name="campo"
                    value={row.camp}
                    checked={row.camp == nomecampo ? true : false}
                    onChange={(e) => (
                      setNomeCampo(e.target.id),
                      setIdCampo(e.target.value),
                      setHour(""),
                      setHorariosOcupados([]),
                      console.log(campos)
                    )}
                  />
                  <label htmlFor={row.camp}>{row.camp}</label>
                </div>
              ))}
            </S.InputRadioRadio>
          </S.InputRadio>

          <S.InputRadioDuracao>
            <span>Duração:</span>
            <S.InputRadioRadio>
              <div className="dura">
                <input
                  type="radio"
                  id="hora1"
                  name="duracao"
                  value="0"
                  checked={tipoHora == 0 ? true : false}
                  onChange={(e) => (
                    setTipoHora(e.target.value),
                    setHorariosOcupados([]),
                    setHour("")
                  )}
                />
                <label htmlFor="hora1" id="hora1">
                  1h30
                </label>
              </div>
              <div className="dura">
                <input
                  type="radio"
                  id="hora2"
                  name="duracao"
                  value="1"
                  checked={tipoHora == 1 ? true : false}
                  onChange={(e) => (
                    setTipoHora(e.target.value),
                    setHorariosOcupados([]),
                    setHour("")
                  )}
                />
                <label htmlFor="hora2" id="hora2">
                  2h
                </label>
              </div>
            </S.InputRadioRadio>
          </S.InputRadioDuracao>
        </S.InputTwoRadio>

        <S.InputTwo>
          <S.InputTwoArea>
            <span>Data</span>
            <DatePicker
              id="InputCalendar"
              selected={date}
              onChange={(date) => (
                setDate(date),
                setHorariosOcupados([]),
                setHour(""),
                getTime(date)
              )}
              locale="pt-BR"
              strictParsing
              dateFormat="dd/MM/yyyy"
              minDate={new Date()}
              placeholderText="--/--/----"
            />
            <FontAwesomeIcon className="fa-lg" icon={faCalendar} />
          </S.InputTwoArea>

          <S.InputTwoArea>
            <span>Horário</span>
            <DatePicker
              id="inputHour"
              selected={hour}
              onChange={(hour) => (
                hour.setDate(date.getDate()),
                hour.setMonth(date.getMonth()),
                hour.setFullYear(date.getFullYear()),
                setHour(hour),
                setHorariosOcupados([])
              )}
              onClick={insertExcludesTimes()}
              showTimeSelect
              showTimeSelectOnly
              dateFormat="p"
              timeCaption="time"
              timeIntervals={30}
              minTime={setMintime()}
              maxTime={setMaxtime()}
              excludeTimes={horariosOcupados}
              locale="pt-BR"
              placeholderText="--:--"
            />
            <FontAwesomeIcon className="fa-lg" icon={faClock} />
          </S.InputTwoArea>
        </S.InputTwo>

        <S.InputTipoAgendamento>
          <span>Tipo do agendamento:</span>
          <S.InputRadioRadio>
            {!match.params.id ? (
              <>
                <div className="dura">
                  <input
                    type="radio"
                    id="tipoAgendamento1"
                    name="tipoAgendamento"
                    value="0"
                    checked={tipoAgendamento == 0 ? true : false}
                    onChange={(e) => (
                      setTipoAgendamento(e.target.value), setPrice()
                    )}
                  />
                  <label htmlFor="tipoAgendamento1" id="tipoAgendamento1">
                    Diária
                  </label>
                </div>
                <div className="dura">
                  <input
                    type="radio"
                    id="tipoAgendamento2"
                    name="tipoAgendamento"
                    value="1"
                    checked={tipoAgendamento == 1 ? true : false}
                    onChange={(e) => (
                      setTipoAgendamento(e.target.value), checkMensalDate()
                    )}
                  />
                  <label htmlFor="tipoAgendamento2" id="tipoAgendamento2">
                    Mensal
                  </label>
                </div>
              </>
            ) : tipoAgendamento == 0 ? (
              <div className="dura">
                <input
                  type="radio"
                  id="tipoAgendamento1"
                  name="tipoAgendamento"
                  value="0"
                  checked={tipoAgendamento == 0 ? true : false}
                  onChange={(e) => (
                    setTipoAgendamento(e.target.value), setPrice()
                  )}
                />
                <label htmlFor="tipoAgendamento1" id="tipoAgendamento1">
                  Diária
                </label>
              </div>
            ) : (
              <div className="dura">
                <input
                  type="radio"
                  id="tipoAgendamento2"
                  name="tipoAgendamento"
                  value="1"
                  checked={tipoAgendamento == 1 ? true : false}
                  onChange={(e) => (
                    setTipoAgendamento(e.target.value), checkMensalDate()
                  )}
                />
                <label htmlFor="tipoAgendamento2" id="tipoAgendamento2">
                  Mensal
                </label>
              </div>
            )}
          </S.InputRadioRadio>
        </S.InputTipoAgendamento>

        <S.Options>
          <div>
            <input
              id="confirm"
              type="checkbox"
              checked={status === 2 ? true : false}
              onChange={() => setStatus(status === 2 ? 1 : 2)}
            ></input>
            <label htmlFor="confirm">Confirmar</label>
          </div>

          {match.params.id && tipoAgendamento == 1 ? (
            <SplitButton
              drop={"up"}
              variant="secondary"
              title={`Cancelar`}
              onClick={() => updateStatus(match.params.id)}
            >
              <Dropdown.Item eventKey="1" onClick={() => cancelAllMensal()}>
                Cancelar mensal
              </Dropdown.Item>
            </SplitButton>
          ) : (
            match.params.id && (
              <button
                type="button"
                onClick={() => updateStatus(match.params.id)}
              >
                Cancelar
              </button>
            )
          )}
        </S.Options>

        <S.Save>
          <button type="button" onClick={Save}>
            SALVAR
          </button>
        </S.Save>
      </S.Form>
    </S.Container>
  );
}

export default Task;
