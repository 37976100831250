import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import Home from "../views/Home";
import Task from "../views/Task";
import Login from "../views/Login";
import Agenda from "../views/Agenda";
import Politica from "../views/Politica";
import Termos from "../views/Termos";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/termos" exact component={Termos} />
        <Route path="/politica" exact component={Politica} />
        <Route path="/Agenda" exact component={Agenda} />
        <Route path="/login" exact component={Login} />
        <Route path="/App" exact component={Home} />
        <Route path="/task" exact component={Task} />
        <Route path="/task/:id" exact component={Task} />
        <Route path="*">
          <Redirect to="/login" />{" "}
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
