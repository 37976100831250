import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

`;

export const Form = styled.form`
  width: 400px;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin: 10px 0 40px;
  }

  hr {
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #cdcdcd;
    width: 100%;
  }
  
  input {
    flex: 1;
    margin-bottom: 15px;
    padding: 15px 5%;
    color: #777;
    font-size: 15px;
    width: 90%;
    border: 1px solid #ddd;

    &::placeholder {
      color: #999;
    }
  }
  
  button {
    color: #fff;
    font-size: 16px;
    background: #26C601;
    height: 56px;
    border: 0;
    border-radius: 5px;
    width: 100%;
  }

  
  @media (max-width: 575.98px) {
    width: 100%;
    height: 100%;
    padding: 0 10%;
  align-items: center;
  justify-content: center;
    
    img {
      margin: 10px 0 10px;
    }
  }

`;

export const Div = styled.div`
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;