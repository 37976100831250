import styled from "styled-components";

export const Container = styled.div`
  width: 85%;
  margin: 0 auto;
  background: #fff;
  margin-bottom: 40px;
  h3 {
    font: Bold 18px Nunito;
    display: inline-block;
    color: #707070;
  }
  .MuiTableCell-root {
    padding: 2px;
  }

  th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignCenter {
    ${"" /* height: 10px !important;   */}
  }

  td.MuiTableCell-root.MuiTableCell-body.botoes.MuiTableCell-alignCenter {
    position: relative;
    top: 1px;
    padding: 4px 0;
    padding-left: 8px;
  }

  .MuiTableCell-root {
    ${"" /* padding: 10px; */}
  }

  .table-body > .table-row {
    background-color: #fff;
  }

  .table-body > .table-row.mensal {
    background-color: #ffffde !important;
  }

  .table-body > .table-row:nth-child(2n) {
    background: #ebffe6;
  }

  a {
    cursor: pointer;
    border: none;
    padding: 0;
    margin-top: 6px;
    background: none;
    -webkit-transition: 0.15s ease-in;
    -moz-transition: 0.15s ease-in;
    -o-transition: 0.15s ease-in;
    transition: 0.15s ease-in;

    img#olhos {
      display: flex;
      width: 35px;
      padding-bottom: 6px;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  button {
    background: none;
    border: none;
    padding: 1;
    cursor: pointer;
    -webkit-transition: 0.15s ease-in;
    -moz-transition: 0.15s ease-in;
    -o-transition: 0.15s ease-in;
    transition: 0.15s ease-in;

    img.table-icon{
      display: flex;
      width: 28px;
      margin-left: 6px;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  .botoes {
    display: flex;
    flex-direction: row;
    padding: 2px;
    padding-left: 8px;
  }

  .nowrap {
    white-space: nowrap;
  }

  @media (max-width: 575.98px) {
    th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignCenter {
      padding: 0px;
      width: 10px !important;
      line-height: 0px !important;
    }

    th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignCenter h3 {
      position: relative;
      margin-block-start: 0em;
      margin-block-end: 0.5em;
      margin-top: 0.5em;
      font-size: 13px !important;
      padding: 0 !important;
    }

    .MuiTableCell-root {
      padding: 0px 2px;
      letter-spacing: 0px;
    }

    .botoes {
      padding-left: 5px;
    }
  }

  div#status {
    width: 15px;
    height: 15px;
    margin: 0 auto;
    border: 1px solid #707070;
    border-radius: 50%;
  }

  @media (min-width: 576px) and (max-width: 724.98px) {
    th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignCenter {
      padding: 0px;
      width: 10px !important;
      line-height: 0px !important;
    }

    th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignCenter h3 {
      position: relative;
      margin-block-start: 0em;
      margin-block-end: 1em;
      margin-top: 1em;
      font-size: 15px !important;
      padding: 0 !important;
    }
  }

  @media (min-width: 725px) and (max-width: 991.98px) {
    td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter {
      padding-left: 10px;
    }
  }

  @media (min-width: 992px) and (max-width: 1208.98px) {
  }

  @media (min-width: 1209px) {
  }
`;
