import React, {useState, useEffect} from 'react';

import {Redirect} from 'react-router-dom';
// Componentes
// ================================
import 'normalize.css';
import Header from '../../components/Header';
import FilterCard from '../../components/FilterCard';
import HorariosButton from '../../components/HorariosButton';
import Table from '../../components/Table';
import api from '../../services/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as S from './style';
import moment from 'moment';

import Modal from "react-bootstrap/Modal";
import { Button } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from "date-fns/locale/pt-BR";
import FloatButton from '../../components/FloatButton';
registerLocale("pt-BR", ptBR);



function Home() {
  const [filterActived, setFilterActived] = useState(!localStorage.getItem('@futplace/filter') ? 'today' : localStorage.getItem('@futplace/filter'));  
  const [redirect, setRedirect] = useState(false);
  const [date, setDate] = useState(!localStorage.getItem("@futplace/date") ? new Date() : new Date(localStorage.getItem("@futplace/date")));
  const [dateHorario, setDateHorario] = useState(new Date());
  const [mes, setMes] = useState(!localStorage.getItem("@futplace/mes") ? new Date() : new Date(localStorage.getItem("@futplace/mes")));
  const [campos, setCampos] = useState([]);
  const [escolhaCampo, setEscolhaCampo] = useState(!localStorage.getItem("@futplace/camp") ? 'all' : localStorage.getItem("@futplace/camp"));
  const [modalDateShow, setModalDateShow] = useState(false);
  const handleDateClose = () => setModalDateShow(false);
  const [modalCampShow, setModalCampShow] = useState(false);
  const handleCampClose = () => setModalCampShow(false);
  const [modalMesShow, setModalMesShow] = useState(false);
  const handleMesClose = () => setModalMesShow(false);

  const [modalDispHorario, setModalDispHorarioShow] = useState(false);
  const handleDispHorarioClose = () => setModalDispHorarioShow(false);
  const [horariosOcupados, setHorariosOcupados] = useState([]);
  const [hour, setHour] = useState();
  const [idempresa] = useState(localStorage.getItem('@futplace/id'));
  const [excludeTimes, setExcludeTimes] = useState();
  const [idcampo, setIdCampo] = useState();
  const [nomecampo, setNomeCampo] = useState();
  const [tipoHora, setTipoHora] = useState();
  const [minhour, setMinHour] = useState(0);
  const [maxhour, setMaxHour] = useState(0);

  
  async function getExcludeTimes () {
    await api.get(`/task/time/${idempresa}`)
    .then(response => {
        setExcludeTimes(response.data);
    })
}

  async function getCamps () {
    await api.get(`/empresa/camps/${idempresa}`)
    .then(response => {
        response.data.forEach((item) => (setCampos(item.camps)))
    })
  }

  function insertExcludesTimes () {
    return excludeTimes && excludeTimes.map((row) =>
        (row.nomecampo == nomecampo ? 
          (moment(moment(dateHorario).format('YYYY-MM-DD')).isSame(moment(row.when).format('YYYY-MM-DD')) ? 
              horariosOcupados.push(
                    (tipoHora == 1 ? new Date(row.when).setHours(new Date(row.when).getHours() - 1, new Date(row.when).getMinutes() - 30 ) : ''),
                    new Date(row.when).setHours(new Date(row.when).getHours() - 1),
                    new Date(row.when).setMinutes(new Date(row.when).getMinutes() - 30), 
                    new Date(row.when), 
                    new Date(row.when).setMinutes(new Date(row.when).getMinutes() + 30), 
                    new Date(row.when).setHours(new Date(row.when).getHours() + 1),
                    (row.tipoHora == 1 ? new Date(row.when).setHours(new Date(row.when).getHours() + 1, new Date(row.when).getMinutes() + 30 ) : '')
              )
          : '') 
        : '')
      )
  }


  function setSelected () {
    if(document.getElementById('campos') != undefined) {
      document.getElementById('campos').value = escolhaCampo;
    }
  }

  function getFilterActived() {
    if(!localStorage.getItem("@futplace/filter")) {
      setFilterActived("today");
    } else {
      setFilterActived(localStorage.getItem("@futplace/filter"));
    }
  }

  function setFilter(filtro) {
    localStorage.setItem("@futplace/filter", filtro)
    
    if(filtro == "month") {
      localStorage.setItem("@futplace/mes" , mes);
    } else if (filtro == "date") { 
      localStorage.setItem("@futplace/date" , date);
    } else if (filtro == "camp") {
      localStorage.setItem("@futplace/camp" , document.getElementById('campos').value);
    }

    setFilterActived(filtro);
  }

  useEffect(() => {
    if(!localStorage.getItem("@futplace")) {
      setRedirect(true);
    }

    getFilterActived();
    getCamps();
    getExcludeTimes();
  }, []);

  
  
    return (
        <S.Container>
            {redirect && <Redirect to="/login"/>}
            <Header/>
            <S.FilterArea>
              <button className="custom-button" type="button" onClick={() => (window.location.replace(`/Agenda/`))}>
                  <HorariosButton tittle="Horários vagos" />
              </button>
              <button className="custom-button" type="button" onClick={() => setFilter("today")}>
                  <FilterCard tittle="Hoje" actived={filterActived === 'today'} />
              </button>
              <button className="custom-button" type="button" onClick={() => setFilter("week")}>
                  <FilterCard tittle="Semana" actived={filterActived === 'week'} />
              </button>
              <button className="custom-button" type="button" onClick={() => (setModalMesShow(true))}>
                  <FilterCard tittle="Mês" actived={filterActived === 'month'} />
              </button>
              <button className="custom-button" type="button" onClick={() => (setModalDateShow(true))}>
                  <FilterCard tittle="Data" actived={filterActived === 'date'} />
              </button>
              <button className="custom-button" type="button" onClick={() => (setModalCampShow(true), getCamps())}>
                  <FilterCard tittle="Campos" actived={(escolhaCampo != 'all'? true : '')} />
              </button>
            </S.FilterArea>

            {/* Data */}
            <Modal
              size="xs"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={modalDateShow}
              onHide={handleDateClose}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                Escolha uma data!
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <DatePicker
                    id="InputCalendar"
                    selected={date}
                    onChange={(date => (setDate(date)))}
                    locale="pt-BR"
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    placeholderText="--/--/----"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => (setModalDateShow(false), setFilter("date"))}>Confirmar</Button>
              </Modal.Footer>
            </Modal>

            {/* Campos */}
            <Modal
              size="xs"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={modalCampShow}
              onHide={handleCampClose}
              onChange={setSelected()}
              
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                Escolha um campo!
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <select name="campos" id="campos">
                    <option value='all'>Todos</option>
                  {campos.map((row, index) => (
                              <option value={row.camp} >{row.camp}</option>
                          ))}
                </select>

              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => (setModalCampShow(false), setEscolhaCampo(document.getElementById('campos').value),setFilter("camp"))}>Confirmar</Button>
              </Modal.Footer>
            </Modal>

            {/* Mes */}
            <Modal
              size="xs"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={modalMesShow}
              onHide={handleMesClose}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                Escolha um mês!
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <DatePicker
                      id="InputCalendar"
                      selected={mes}
                      onChange={(mes => (setMes(mes)))}
                      locale="pt-BR"
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      showFullMonthYearPicker
                  />

              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => (setModalMesShow(false), setFilter("month"))}>Confirmar</Button>
              </Modal.Footer>
            </Modal>

            {/* Horário Vagos */}
            <Modal
              size="xs"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={modalDispHorario}
              onHide={handleDispHorarioClose}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Selecione uma data!
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <S.InputTwoRadio>
                
                <S.InputRadio>
                    <span>Campo:</span>
                    <S.InputRadioRadio>

                        {campos.map((row, index) => (
                            <div key={index}>
                                <input id={row.camp} type="radio" name="campo" value={index} checked={((row.camp) == nomecampo ? true : false)}
                                    onChange={e => (setNomeCampo(e.target.value), setHour(''), setHorariosOcupados([]))} 
                                />
                                <label htmlFor={row.camp}>{row.camp}</label>
                            </div>
                        ))}

                    </S.InputRadioRadio>
                </S.InputRadio>
                  
                <S.InputRadioDuracao>
                    
                    <span>Duração:</span>
                    <S.InputRadioRadio>
                        <div className="dura">
                          <input type="radio" id="hora1" name="duracao" value="0" checked={((tipoHora == 0) ? true : false)} onChange={e => ( setTipoHora(e.target.value), setHorariosOcupados([]), setHour(''))}/>
                          <label htmlFor="hora1" id="hora1">1h30</label>
                        </div>
                        <div className="dura">
                          <input type="radio" id="hora2" name="duracao" value="1" checked={(tipoHora == 1 ? true : false)} onChange={e =>  (setTipoHora(e.target.value), setHorariosOcupados([]), setHour(''))}/>
                          <label htmlFor="hora2" id="hora2">2h</label>
                        </div>
                    </S.InputRadioRadio>
                </S.InputRadioDuracao>
              </S.InputTwoRadio>
                  <S.InputTwo>  

                    <S.InputTwoArea>
                        <span>Data</span>
                        <DatePicker
                            id="InputCalendar"
                            selected={dateHorario}
                            onChange={(dateHorario => (setDateHorario(dateHorario), setHorariosOcupados([]), setHour('')))}
                            locale="pt-BR"
                            strictParsing
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                            placeholderText="--/--/----"
                        />
                        
                    </S.InputTwoArea>


                    <S.InputTwoArea>
                        <span>Horário</span>
                        <DatePicker
                            id="inputHour"
                            selected={hour}
                            onChange={hour => (hour.setDate(dateHorario.getDate()), hour.setMonth(dateHorario.getMonth()) ,setHour(hour), setHorariosOcupados([]))}
                            onClick={insertExcludesTimes()}
                            showTimeSelect
                            showTimeSelectOnly
                            dateFormat="p"
                            timeCaption="time"
                            timeIntervals={30}
                            minTime={new Date().setHours(minhour, (minhour.length > 2 ? 30 : 0))}
                            maxTime={new Date().setHours(maxhour, (maxhour.length > 2 ? 30 : 0))}
                            excludeTimes={
                              horariosOcupados
                            }
                            locale="pt-BR"
                            placeholderText="--:--"
                            
                        />
                    </S.InputTwoArea>
                    
                </S.InputTwo>
                
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => (setModalDispHorarioShow(false))}>OK</Button>
              </Modal.Footer>
            </Modal>

            

            <S.Tittle>
                <div className='left'></div>
                <h3 className="agendamento">Agendamentos</h3>
                
            </S.Tittle>



            <Table filtro={filterActived} date={date} nomecampo={escolhaCampo} mes={mes}>

            </Table>

            <FloatButton/>
            
        </S.Container>
    )
}

export default Home;
