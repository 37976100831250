import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const FilterArea = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 30px;

    button.custom-button, a.custom-button {
        background: none;
        border: none;

        *, *::before, *::after {
            box-sizing: content-box;
        }
    }
    
`