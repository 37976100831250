import React, { useState, useEffect } from "react";
import * as S from "./style";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import api from "../../services/api";
import olhos from "../../assets/olhos.png";
import check from "../../assets/check.png";
import trash from "../../assets/lixo.png";
import { addHours, addMinutes } from "date-fns";
import moment from "moment";
import swal from "sweetalert";

const useStyles = makeStyles({
  root: {
    display: "flex",
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  cancelMensal: {
    "& .swal-title": {
      fontSize: "20px",
    },
    "& .swal-footer": {
      textAlign: "center",
    },
    "& .mensal-button": {
      color: "#555",
      backgroundColor: "#efefef",
      "&:hover": {
        backgroundColor: "#e8e8e8",
      },
    },
  },
});

function getbackgroundcolor(status) {
  let color = "";

  switch (status) {
    // Recusado
    case 0:
      color = "grey";
      break;

    // Pendente
    case 1:
      color = "yellow";
      break;

    //Confirmado
    case 2:
      color = "green";
      break;

    // Cancelado
    case 3:
      color = "red";
      break;
  }

  return color;
}

function Card({ filtro, date, nomecampo, mes }) {
  const idempresa = localStorage.getItem("@futplace/id");
  const classes = useStyles();
  const [agendamentos, setAgendamento] = useState([]);
  const [alreadyLoad, setAlreadyLoad] = useState(false);
  const [lastFilter, setLastFilter] = useState(filtro);
  const [lastMonth, setLastMonth] = useState(mes);
  const [lastDate, setLastDate] = useState(date);
  const [lastIdCampo, setLastIdCampo] = useState(nomecampo);
  const [lastNomecampo, setLastNomeCampo] = useState(nomecampo);

  async function loadAgendamentos() {
    if (filtro == "date") {
      setLastFilter("date");
      setLastDate(date);

      await api
        .get(
          `/task/filter/${filtro}/${idempresa}/${moment(date).format(
            "YYYY-MM-DD"
          )}`
        )
        .then((response) => {
          setAgendamento(response.data);
          setAlreadyLoad(true);
        });
    } else if (filtro == "camp") {
      setLastFilter("camp");
      setLastNomeCampo(nomecampo);

      await api
        .get(`/task/filter/${filtro}/${idempresa}/${nomecampo}`)
        .then((response) => {
          setAgendamento(response.data);
          setAlreadyLoad(true);
        });
    } else if (filtro == "month") {
      setLastFilter("month");
      setLastMonth(mes);

      await api
        .get(
          `/task/filter/${filtro}/${idempresa}/${moment(mes).format(
            "YYYY-MM-15"
          )}`
        )
        .then((response) => {
          setAgendamento([]);
          setAgendamento(response.data);
          setAlreadyLoad(true);
        });
    } else {
      setLastFilter(filtro);

      await api.get(`/task/filter/${filtro}/${idempresa}`).then((response) => {
        setAgendamento(response.data);
        setAlreadyLoad(true);
      });
    }
  }

  async function updateStatusAgendamento(_id, status) {
    if (status === "confirmar") {
      swal({
        title: "Tem certeza?",
        text: "Você irá confirmar o agendamento!",
        icon: "warning",
        buttons: ["Cancelar", "OK"],
      }).then((willDelete) => {
        if (willDelete) {
          api.put(`/task/updatestatus/${_id}/2`).then(() => loadAgendamentos());

          swal("Agendamento Confirmado!", {
            icon: "success",
          });
        }
      });
    } else if (status === "excluir") {
      swal({
        title: "Tem certeza?",
        text: "Você irá cancelar o agendamento!",
        icon: "warning",
        buttons: ["Cancelar", "OK"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          api.put(`/task/updatestatus/${_id}/3`).then(() => loadAgendamentos());

          swal("Agendamento cancelado!", {
            icon: "success",
          });
        }
      });
    }
  }

  async function cancelMensalAgendamento(schedule) {
    swal({
      title: "Você irá cancelar um agendamento mensal?",
      icon: "warning",
      buttons: {
        defeat: {
          text: "Somente a corrente",
          value: "current",
          visible: true,
          className: "mensal-button",
          closeModal: true,
        },
        confirm: {
          text: "Cancelar mensal",
          value: "all",
          visible: true,
          className: "mensal-button",
          closeModal: true,
        },
      },
      className: classes.cancelMensal,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete == "all") {
        cancelAllMensal(schedule);
      } else if (willDelete == "current") {
        updateStatusAgendamento(schedule._id, "excluir");
      }
    });
  }

  async function cancelAllMensal(schedule) {
    swal({
      title: "Tem certeza?",
      text: "Você irá cancelar o agendamento atual e todos os seguintes!",
      icon: "warning",
      buttons: ["Cancelar", "OK"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        cancelAllMensalProcess(schedule);

        swal("Agendamento cancelado!", {
          icon: "success",
        });
      }
    });
  }

  // Processo para cancelar as mensais de um cliente
  async function cancelAllMensalProcess(schedule) {
    await api
      .get(`/task/findmensal/${schedule.idagendamento}`)
      .then((response) => {
        response.data.forEach((item) => {
          if (
            new Date(item.when).getTime() >= new Date(schedule.when).getTime()
          ) {
            api.put(`/task/updatestatus/${item._id}/3`);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => loadAgendamentos());
  }

  useEffect(() => {
    if (
      alreadyLoad == false ||
      lastFilter != filtro ||
      lastNomecampo != nomecampo ||
      lastDate != date ||
      lastMonth != mes
    ) {
      loadAgendamentos();
    }

    const interval = setInterval(() => {
      loadAgendamentos();
    }, 30000);

    return () => clearInterval(interval);
  }, [filtro, nomecampo, date, mes]);

  return (
    <S.Container>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">
                <h3>ID</h3>
              </TableCell>
              <TableCell align="center">
                <h3>Tipo</h3>
              </TableCell>
              <TableCell align="center">
                <h3>Status</h3>
              </TableCell>
              <TableCell align="center">
                <h3>Campo</h3>
              </TableCell>
              <TableCell align="center">
                <h3>Data</h3>
              </TableCell>
              <TableCell align="center">
                <h3>Horário</h3>
              </TableCell>
              <TableCell align="center">
                <h3>Nome</h3>
              </TableCell>
              <TableCell align="center">
                <h3>Telefone</h3>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "table-body" }}>
            {agendamentos.map((row, index) =>
              nomecampo != "all" ? (
                nomecampo == row.nomecampo ? (
                  <TableRow
                    classes={{
                      root: `table-row ${
                        row.tipoAgendamento == 1 ? "mensal" : ""
                      }`,
                    }}
                    key={row._id}
                  >
                    <TableCell className="botoes" align="center">
                      <Link to={`/task/${row._id}`}>
                        <img
                          className="table-icon"
                          id="olhos"
                          src={olhos}
                        ></img>
                      </Link>
                      <button
                        onClick={() =>
                          updateStatusAgendamento(row._id, "confirmar")
                        }
                      >
                        <img
                          className="table-icon"
                          id="check"
                          src={check}
                        ></img>
                      </button>
                      {row.tipoAgendamento == 1 ? (
                        <button onClick={() => cancelMensalAgendamento(row)}>
                          <img
                            className="table-icon"
                            id="trash"
                            src={trash}
                          ></img>
                        </button>
                      ) : (
                        <button
                          onClick={() =>
                            updateStatusAgendamento(row._id, "excluir")
                          }
                        >
                          <img
                            className="table-icon"
                            id="trash"
                            src={trash}
                          ></img>
                        </button>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <div
                        id="status"
                        style={{
                          backgroundColor: `${getbackgroundcolor(row.status)}`,
                          margin: "0 auto",
                        }}
                      ></div>
                    </TableCell>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      {(row.tipo === 1 && "Online") ||
                        (row.tipo === 0 && "Manual")}
                    </TableCell>
                    <TableCell align="center">
                      {(row.status === 0 && "Recusado") ||
                        (row.status === 1 && "Pendente") ||
                        (row.status === 2 && "Confirmado") ||
                        (row.status === 3 && "Cancelado")}
                    </TableCell>
                    <TableCell align="center">{row.nomecampo}</TableCell>
                    <TableCell align="center">
                      {new Date(
                        new Date(row.when).setHours(
                          new Date(row.when).getHours() + 3
                        )
                      ).toLocaleDateString("pt-br")}
                    </TableCell>
                    <TableCell className="nowrap" align="center">{`${new Date(
                      new Date(row.when).setHours(
                        new Date(row.when).getHours() + 3
                      )
                    ).toLocaleTimeString("pt-br", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })} ~ ${
                      row.tipoHora === 1
                        ? addHours(
                            new Date(
                              new Date(row.when).setHours(
                                new Date(row.when).getHours() + 3
                              )
                            ),
                            2
                          ).toLocaleTimeString("pt-br", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        : addHours(
                            addMinutes(
                              new Date(
                                new Date(row.when).setHours(
                                  new Date(row.when).getHours() + 3
                                )
                              ),
                              30
                            ),
                            1
                          ).toLocaleTimeString("pt-br", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                    }`}</TableCell>
                    <TableCell align="center" className="nowrap">
                      {row.nome}
                    </TableCell>
                    <TableCell align="center" className="nowrap">
                      {row.cellphone}
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )
              ) : (
                <TableRow
                  classes={{
                    root: `table-row ${
                      row.tipoAgendamento == 1 ? "mensal" : ""
                    }`,
                  }}
                  key={row._id}
                >
                  <TableCell className="botoes" align="center">
                    <Link to={`/task/${row._id}`}>
                      <img className="table-icon" id="olhos" src={olhos}></img>
                    </Link>
                    <button
                      onClick={() =>
                        updateStatusAgendamento(row._id, "confirmar")
                      }
                    >
                      <img className="table-icon" id="check" src={check}></img>
                    </button>
                    {row.tipoAgendamento == 1 ? (
                      <button onClick={() => cancelMensalAgendamento(row)}>
                        <img
                          className="table-icon"
                          id="trash"
                          src={trash}
                        ></img>
                      </button>
                    ) : (
                      <button
                        onClick={() =>
                          updateStatusAgendamento(row._id, "excluir")
                        }
                      >
                        <img
                          className="table-icon"
                          id="trash"
                          src={trash}
                        ></img>
                      </button>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <div
                      id="status"
                      style={{
                        backgroundColor: `${getbackgroundcolor(row.status)}`,
                        margin: "0 auto",
                      }}
                    ></div>
                  </TableCell>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {(row.tipo === 1 && "Online") ||
                      (row.tipo === 0 && "Manual")}
                  </TableCell>
                  <TableCell align="center">
                    {(row.status === 0 && "Recusado") ||
                      (row.status === 1 && "Pendente") ||
                      (row.status === 2 && "Confirmado") ||
                      (row.status === 3 && "Cancelado")}
                  </TableCell>
                  <TableCell align="center">{row.nomecampo}</TableCell>
                  <TableCell align="center">
                    {new Date(
                      new Date(row.when).setHours(
                        new Date(row.when).getHours() + 3
                      )
                    ).toLocaleDateString("pt-br")}
                  </TableCell>
                  <TableCell className="nowrap" align="center">{`${new Date(
                    new Date(row.when).setHours(
                      new Date(row.when).getHours() + 3
                    )
                  ).toLocaleTimeString("pt-br", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })} ~ ${
                    row.tipoHora === 1
                      ? addHours(
                          new Date(
                            new Date(row.when).setHours(
                              new Date(row.when).getHours() + 3
                            )
                          ),
                          2
                        ).toLocaleTimeString("pt-br", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : addHours(
                          addMinutes(
                            new Date(
                              new Date(row.when).setHours(
                                new Date(row.when).getHours() + 3
                              )
                            ),
                            30
                          ),
                          1
                        ).toLocaleTimeString("pt-br", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                  }`}</TableCell>
                  <TableCell className="nowrap" align="center">
                    {row.nome}
                  </TableCell>
                  <TableCell className="nowrap" align="center">
                    {row.cellphone}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </Paper>
    </S.Container>
  );
}

export default Card;
