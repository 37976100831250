import React, { useState, useEffect } from "react";
import * as S from "./style";

// Componentes
// ================================
import logo from "../../assets/logo-futplace@2x.png";
import logoicone from "../../assets/logo-futplace-refeito.png";

function Politica() {
  useEffect(() => {}, []);

  return (
    <S.Container>
      <S.Header>
        <div>
          <img id="logo" src={logo} alt="Logo Futplace" />
          <span id="icone">
            <img src={logoicone}></img>
          </span>
        </div>
      </S.Header>
      <S.Politica>
        <h4 align="center">POLÍTICAS DE PRIVACIDADE</h4>
        <br />
        <br />
        <strong>&nbsp;&nbsp;&nbsp;&nbsp;1. DISPOSIÇÕES INICIAIS</strong>
        <br />
        <br />
        <strong>1.1.</strong> A sua privacidade é importante para nós. É
        política da <strong>FUTPLACE DESENVOLVIMENTO DE APP LDTA</strong>{" "}
        respeitar a sua privacidade em relação a qualquer informação sua que
        possamos coletar no aplicativo Futplace.
        <br />
        <br />
        <strong>1.2.</strong> Solicitamos informações pessoais apenas quando 
        realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por 
        meios justos e legais, com o seu conhecimento e consentimento.
        <br />
        <br />
        <strong>1.3.</strong> Também informamos por que estamos coletando e como
        será usado.
        <br />
        <br />
        <strong>1.4.</strong> Apenas retemos as informações necessárias para 
        controle cadastral, manutenção atualizada do histórico das reservas que 
        são feitas e contatar os usuários, para melhor fornecer o serviço solicitado.
        <br />
        <br />
        <strong>1.5.</strong> Quando armazenamos dados, os protegemos dentro de 
        meios comercialmente aceitáveis para evitar perdas e roubos, bem como 
        acesso, divulgação, cópia, uso ou modificação não autorizados.
        <br />
        <br />
        <strong>1.6.</strong> Não compartilhamos informações de identificação 
        pessoal publicamente ou com terceiros, exceto com os usuários parceiros, 
        para poder realizar as reservas, e quando exigido por lei.
        <br />
        <br />
        <strong>1.7.</strong> O nosso aplicativo pode ou não ter links para sites 
        e/ou aplicativos externos que não são operados por nós. Esteja ciente de que 
        não temos controle sobre o conteúdo e práticas desses sites e não podemos 
        aceitar responsabilidade por suas respectivas políticas de privacidade.
        <br />
        <br />
        <strong>1.8.</strong> Você é livre para recusar a nossa solicitação de 
        informações pessoais, entendendo que talvez não possamos fornecer alguns 
        dos serviços desejados.
        <br />
        <br />
        <strong>1.9.</strong> O uso continuado de nosso aplicativo será considerado 
        como aceitação de nossas práticas em torno de privacidade e informações pessoais. 
        Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações 
        pessoais, entre em contato conosco.
        <br />
        <br />
        <strong>&nbsp;&nbsp;&nbsp;&nbsp;2. DEFINIÇÕES</strong>
        <br />
        <br />
        <strong>2.1.</strong> Para os fins desta Política de Privacidade,
        consideram-se:
        <br />
        <br />
        <strong>I.</strong> Endereço de Protocolo de Internet (Endereço IP): 
        o código atribuído a um Terminal de uma rede para permitir sua identificação, 
        definido segundo parâmetros internacionais;
        <br />
        <strong>II.</strong> Internet: o sistema constituído do conjunto de protocolos 
        lógicos, estruturado em escala mundial para uso público e irrestrito, com a finalidade 
        de possibilitar a comunicação de dados entre terminais por meio de diferentes redes;

        <br />
        <strong>III.</strong> Site e/ou Aplicativo: portais, podendo ser site ou aplicativo da 
        Futplace por meio dos quais os Usuários acessam os serviços e conteúdos disponibilizados 
        pela Futplace;
        <br />
        <strong>IV.</strong> Terminais: computadores, notebooks, netbooks, smartphones, tablets e 
        quaisquer outros dispositivos que se conectam a Internet e são compatíveis com o aplicativo 
        ou site da Futplace;
        <br />
        <strong>V.</strong> Usuários: todas as pessoas físicas que utilizarão o aplicativo, 
        totalmente capazes de praticar os atos da vida civil ou os absolutamente ou relativamente 
        incapazes devidamente representados ou assistidos;
        <br />
        <strong>VI.</strong> Usuários clientes: aqueles que utilizarem a plataforma para realizar 
        a reserva dos campos de futebol;
        <br />
        <strong>VII.</strong> Usuários parceiros: aqueles que utilizarem a plataforma para receberem 
        os pedidos em seus estabelecimentos e fazer a reserva dos horários, sendo credenciados com a 
        Futplace mediante contrato de prestação de serviços;
        <br />
        <strong>VIII.</strong> Desenvolvedores: pessoas que trabalhem no desenvolvimento técnico do 
        site e/ou aplicativo, responsáveis por eventuais erros e falhas no sistema;
        <br />
        <strong>IX.</strong> Moderadores: funcionários da Futplace, responsável por administrar o 
        site e/ou aplicativo para melhor funcionamento deste.
        <br />
        <br />
        <strong>2.2.</strong> Esta Política de Privacidade deverá ser regida e
        interpretada de acordo com os seguintes princípios:
        <br />
        <br />
        <strong>I.</strong> Os cabeçalhos e títulos servem apenas para 
        conveniência de referência e não limitarão ou afetarão o significado das cláusulas, 
        parágrafos ou itens aos quais se aplicam;

        <br />
        <strong>II.</strong> Os termos “inclusive”, “incluindo” e outros termos semelhantes 
        serão interpretados como se estivessem acompanhados da frase “a título meramente exemplificativo” e “sem limitação”;
        <br />
        <strong>III.</strong> Sempre que exigido pelo contexto, as definições contidas nesta 
        Política de Privacidade serão aplicadas tanto no singular quanto no plural e o gênero 
        masculino incluirá o feminino e vice-versa, sem alteração de significado;

        <br />
        <strong>IV.</strong> Referências a qualquer documento ou outros instrumentos incluem todas as suas alterações, 
        substituições e consolidações e respectivas complementações, salvo se expressamente disposto de forma diferente 
        nesta Política de Privacidade.
        <br />
        <br />
        <strong>&nbsp;&nbsp;&nbsp;&nbsp;3. OBJETO</strong>
        <br />
        <br />
        <strong>3.1</strong> Esta Política de Privacidade apresenta as condições gerais 
        aplicáveis ao tratamento de dados  referente ao uso dos serviços oferecidos pela 
        Futplace por meio do seu site e/ou aplicativo, incluindo a seção de reserva de 
        campos credenciados pelos usuários parceiros.
        <br />
        <br />
        <strong>&nbsp;&nbsp;&nbsp;&nbsp;4. RESPONSABILIDADES</strong>
        <br />
        <br />
        <strong>4.1.</strong>  O Usuário não poderá praticar as seguintes ações com 
        relação ao site e/ou aplicativo, no todo ou em parte, sem prejuízo de outras 
        que sejam consideradas ilegais, contrariem a ordem pública ou atentem contra 
        a moral e os bons costumes: 
        <br />
        <br />
        <strong>I.</strong> prática de quaisquer atos ilícitos e/ou violação da 
        legislação vigente, inclusive das disposições da Lei 9.613/98 e da Lei 12.846/13; 
        <br />
        <strong>II.</strong> Utilizar o site e/ou aplicativo e/ou qualquer conteúdo dele 
        constante, no todo ou em parte, sob qualquer meio ou forma, com propósito diverso 
        daquele a que este se destina e de forma diversa da prevista neste Termo, 
        inclusive divulgando, a qualquer título, a terceiros que não tenham ou 
        não devam ter acesso ao site e/ou aplicativo; 
        <br />
        <strong>III.</strong> Apagar, deturpar, corromper, alterar, editar, adaptar, transmitir 
        ou de qualquer forma modificar, sob qualquer meio ou forma, no todo ou em parte, 
        o site e/ou aplicativo, e/ou qualquer conteúdo dele constante; 
        <br />
        <strong>IV.</strong> Fazer publicidade ou marketing associando sua 
        imagem a Futplace, ou a qualquer empresa afiliada, controlada, coligada 
        ou pertencente ao seu grupo econômico, para qualquer divulgação dos dados 
        contidos neste site e/ou aplicativo, bem como de suas marcas, recomendamos 
        o contato via e-mail com contato@futplace.com.br; 
        <br />
        <strong>V.</strong>
        Carregar, enviar e/ou transmitir qualquer conteúdo de cunho erótico, pornográfico, 
        obsceno, difamatório ou calunioso ou que façam apologia ao crime, uso de drogas, 
        consumo de bebidas alcoólicas ou de produtos fumígenos, violência física ou moral; 
        <br />
        <strong>VI.</strong> Carregar, enviar e/ou transmitir qualquer conteúdo que promova ou 
        incite o preconceito (inclusive de origem, raça, sexo, cor, orientação sexual e idade) 
        ou qualquer forma de discriminação, bem como o ódio ou atividades ilegais; 
        <br />
        <strong>VII.</strong> Ameaçar, coagir, ou causar constrangimento físico 
        ou moral aos demais Usuários e funcionários da Futplace; 
        <br />
        <strong>VIII.</strong> Realizar atos que causem ou propiciem a contaminação 
        ou prejudiquem quaisquer equipamentos da Edenred, de suas empresas e/ou de 
        terceiros, inclusive por meio de vírus, trojans, malware, worm, bot, backdoor, 
        spyware, rootkit, ou por quaisquer outros dispositivos que venham a ser criados; 
        <br />
        <strong>IX.</strong> Praticar quaisquer atos em relação ao site e/ou aplicativo, 
        direta ou indiretamente, no todo ou em parte, que possam causar prejuízo à Futplace, 
        a qualquer Usuário e/ou a quaisquer terceiros; 
        <br />
        <strong>X.</strong> Usar qualquer nome empresarial, marca, nome de domínio, slogan ou 
        expressão de propaganda ou qualquer sinal distintivo ou bem de propriedade intelectual 
        de titularidade da Futplace, ou de qualquer empresa afiliada, controlada, coligada ou 
        pertencente ao seu grupo econômico. 
        <br />
        <br />
        <strong>4.2.</strong> O Usuário é exclusivamente responsável:
        <br />
        <br />
        <strong>I.</strong> Por todos e quaisquer atos ou omissões por ele 
        realizados no ambiente do site e/ou aplicativo; 
        <br />
        <strong>II.</strong> Pela reparação de todos e quaisquer danos, diretos 
        ou indiretos, que sejam causados à Futplace, a qualquer outro Usuário, ou, 
        ainda, a qualquer terceiro, inclusive em virtude do descumprimento do disposto 
        neste Termo. Sem prejuízo de outras medidas, a Futplace poderá, por si ou por 
        terceiros, a qualquer tempo, a seu exclusivo critério, sem necessidade de qualquer
         aviso ou notificação prévia, suspender o acesso ao site e/ou aplicativo de qualquer 
         Usuário, a qualquer tempo, caso este descumpra qualquer disposição deste Termo e 
         demais políticas do site e/ou aplicativo ou da legislação vigente aplicável;
        <br />
        <strong>III.</strong> Pela reparação ou compensação de quaisquer prejuízos que 
        der causa, direta ou indiretamente, sejam eles materiais ou não, tais como 
        danos causados à infraestrutura do espaço reservado ou desmarcação do horário 
        agendado, estando, portanto, obrigado a arcar com as despesas em questão. 
        <br />
        <br />
        <strong>4.3.</strong> Em nenhuma hipótese, a Futplace será responsável:
        <br />
        <br />
        <strong>I.</strong> Pelo pagamento de custos ou danos, inclusive danos diretos, 
        indiretos, específicos, acidentais ou emergentes, decorrentes do acesso ou uso, 
        impossibilidade de acesso ou uso deste site e/ou aplicativo ou de alguma de suas 
        funções ou partes, inclusive com o fim de consultar ou baixar informações, dados, 
        textos, imagens ou outros materiais acessíveis por meio deste site e/ou aplicativo; 
        <br />
        <strong>II.</strong> Por qualquer ato ou omissão realizado e/ou dano causado pelo 
        Usuário neste site e/ou aplicativo ou em instalações de terceiros e membros parceiros; 
        <br />
        <strong>III.</strong> Pela instalação no equipamento do Usuário ou de terceiros, 
        de vírus, trojans, malware, worm, bot, backdoor, spyware, rootkit, ou de quaisquer 
        outros dispositivos que venham a ser criados, em decorrência da navegação na Internet pelo Usuário; 
        <br />
        <strong>IV.</strong> Pelo uso indevido por qualquer Usuário ou terceiros do site e/ou aplicativo, 
        no todo ou em parte, por qualquer meio ou forma, incluindo, mas não se limitando à reprodução 
        e/ou divulgação em quaisquer meios; 
        <br />
        <strong>V.</strong> Pela suspensão, interrupção ou remoção do aplicativo;
        <br />
        <strong>VI.</strong> Por qualquer dano direto, indireto, acidental, excepcional 
        ou resultante do uso ou inaptidão do desfrute dos seus serviços, 
        ou pelo custo da aquisição de serviços substitutos; 
        <br />
        <strong>VII.</strong> Por custos ou pagamentos de responsabilidade do Usuário, 
        tais como os provenientes do serviço prestado pelo estabelecimento em favor do Usuário. 
        <br />
        <br />
        <strong>4.4</strong> O Usuário se declara ciente que a Futplace não controla os 
        conteúdos ou produtos transmitidos, difundidos ou disponibilizados a 
        terceiros pelo estabelecimento no uso das funcionalidades, bem como, 
        que a Futplace não é a proprietária dos produtos ou responsável pela 
        qualidade dos produtos e serviços ofertados, e que não intervém na entrega 
        dos produtos ou na prestação dos serviços, fica certo desde já, que o 
        estabelecimento é o único e exclusivo responsável pelo conteúdo da oferta 
        disponibilizada, respondendo integralmente pelos eventuais danos que tenha 
        dado causa, inclusive por ação e/ou omissão. 
        <br />
        <br />
        <strong>4.5</strong> Fica certo desde já que a Futplace não será responsabilizada 
        pelo não funcionamento do aplicativo ou decorrente de falha ou interrupção de 
        qualquer serviço de telecomunicação, conexão à internet, provedor de acesso à 
        internet ou de qualquer outro provedor de comunicações, caso fortuito, força maior 
        ou de qualquer outra falha ou problema não atribuíveis à Futplace.
        <br />
        <br />
        <strong>4.6</strong> A responsabilidade de fornecimento da promoção, disponibilidade e 
        prestação do serviço é do Estabelecimento, sendo certo que a Futplace apenas funciona 
        como plataforma de gerenciamento de reservas.
        <br />
        <br />
        <strong>&nbsp;&nbsp;&nbsp;&nbsp;5. DISPOSIÇÕES GERAIS</strong>
        <br />
        <br />
        <strong>5.1</strong> A presente versão desta Política de Privacidade foi
        atualizada pela última vez em 02/10/2020.
        <br />
        <br />
        <strong>5.2</strong> Ao editor se reserva o direito de modificar, a 
        qualquer momento e sem qualquer aviso prévio o aplicativo, as presentes 
        normas, especialmente para adaptá-los às evoluções do aplicativo 
        Futplace seja pela disponibilização de novas funcionalidades, seja 
        pela supressão ou modificação daquelas já existentes.
        <br />
        <br />
        <strong>5.3</strong> Dessa forma, convida-se o Usuário a consultar 
        periodicamente esta página para verificar as atualizações.
        <br />
        <br />
        <strong>5.4</strong> Ao utilizar o serviço após eventuais modificações, 
        o Usuário demonstra sua concordância com as novas normas. Caso discorde 
        de alguma das modificações, deverá pedir, imediatamente, o cancelamento 
        de sua conta e apresentar a sua ressalva ao serviço de atendimento, se 
        assim o desejar.
        <br />
        <br />
        <strong>5.5</strong> Para a solução das controvérsias decorrentes do 
        presente instrumento, será aplicado integralmente o Direito brasileiro.
        <br />
        <br />
        <strong>5.6</strong> Os eventuais litígios deverão ser apresentados no 
        foro da comarca em que se encontra a sede do editor do aplicativo.
      </S.Politica>
    </S.Container>
  );
}

export default Politica;
