import styled from 'styled-components';

export const Container = styled.div`
    width: 210px;
    height: 40px;
    background: ${props => props.actived ? '#26C601' : '#FFF'};
    border-radius: 10px;
    box-shadow: 0px 0px 10px #0000007B;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    
    

    img {
        width: 25px;
        height: 27px;
    }
    
    span {
        font: Bold 24px Arial;
        color: ${props => props.actived ? '#FFF' : '#26C601'};
    }

    &:hover {
        background: #26C601;
        
        span {
            color: white;
        }
    }

    @media (max-width: 575.98px) {
      margin-bottom: 5px;
      padding: 5px;
      width: 40vw;

      img {
        width: 19px;
        height: 21px;
      }
      
      span {
          font-size: 16px;
      }
    }

    @media (min-width: 576px) and (max-width: 724.98px) {
      margin-bottom: 5px;
      width: 25vw;
      padding: 7px;
      img {
        width: 22px;
        height: 24px;
      }
      
      span {
          font-size: 19px;
      }
    }

    @media (min-width: 725px) and (max-width: 991.98px) {
      width: 25vw;
      margin-bottom: 5px;

      span {
          font-size: 21px;
      }
    }

    @media (min-width: 992px) and (max-width: 1208.98px) {
      width: 25vw;
      margin-bottom: 5px;
    }

    @media (min-width: 1209px) {
      width: 13vw;
    }
`