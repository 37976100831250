import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div`
  width: 100%;
  height: 60px;
  background: white;
  border-bottom: 3px solid #26c601;
  padding-left: 100px;
  display: flex;
  align-items: center;

  img {
    width: 164px;
    height: 50px;
  }

  span#icone {
    display: none;
  }

  span#icone img {
    padding-top: 2px;
    width: 29px;
    height: 40px;
  }

  @media (max-width: 400px) {
    padding-left: 0px;

    span#icone {
      display: block;
      padding-left: 5px;
    }

    img#logo {
      display: none;
    }
  }

  @media (min-width: 401px) and (max-width: 575.98px) {
    span#icone {
      display: block;
      padding-left: 15px;
    }

    img#logo {
      display: none;
    }
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    padding-right: 0px;

    a {
      font-size: 1.5em;
    }
  }

  @media (max-width: 767.98px) {
    padding-left: 20px;
  }
`;

export const Termos = styled.div`
  margin: 100px 0;
  width: 60%;
  padding: 30px 60px;
  text-align: justify;
  text-justify: inter-word;
  background: white;
  height: 600px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 8px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 400px) {
    margin: 20px 0;
    width: 90%;
    padding: 16px 30px;
  }

  @media (min-width: 401px) and (max-width: 575.98px) {
    margin: 20px 0;
    width: 90%;
    padding: 16px 30px;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    margin: 20px 0;
    width: 90%;
    padding: 16px 30px;
  }

  @media (min-width: 767.99px) and (max-width: 1023.98px) {
    margin: 20px 0;
    width: 90%;
    padding: 16px 30px;
  }
`;
