import React from 'react';
import * as S from './style';

import filter from '../../assets/HorariosButton.png'

function HorariosButton({tittle, actived}) {
  return (
    <S.Container actived={actived}>
      <img src={filter} alt="Filtro"/>
      <span>{tittle}</span>
    </S.Container>
  );
}

export default HorariosButton;
